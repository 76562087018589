import { Component, OnInit, ViewChildren, ViewChild, QueryList, ViewEncapsulation, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatTooltip } from "@angular/material";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DashboardService } from './dashboard.service';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
declare var $: any;
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { environment } from 'src/environments/environment';
import { ExcelService } from '../service/excel.service';
import { TooltipPosition } from '@angular/material';
import { EnrollService } from '../enroll-assosiate/enroll-assosiate.service';
import { AppComponent } from '../app.component';
import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';

export interface UserData {
  name: string;
  enrollmentId: string;
  status: string;
  spinID: string;
  modifiedDate: string;
  vehiclevin: string;
  lfsvalidity: string;
  payduecdandLfspaycd: string;
}

export interface EnrollData {
  enrollmentNumber: string;
  enrollmentStatus: string;
  name: string;
  lastModifiedDate: string;
  spinId: string;
  vehiclevin: string;
  lfsvalidity: string;
  payduecdandLfspaycd: string;
}

export interface EligibleData {
  FIRST_NAME: string;
  LAST_NAME: string;
  Middle_name: string;
  SPIN_ID: string;
  cert_level: string;
  Status: string;
  spinId: string;
  TOTAL_CRED: string;
  FOUNDATIONS: string;
  TENURE: string;
}

export interface PaymentData {
  name: string;
  spinId: string;
  paymentPeriod: string;
  paymentNo: string;
  invoiceNumber: string;
}

export interface TransferData {
  name: string;
  spin_id: string;
  enrolled_dt: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  filteredUsers: any[] = [];
  usersForm: FormGroup;
  isLoading = false;
  isSpinTouched = false;
  spinId: any = "";
  userList: any[] = [];
  spinIdClicked: Boolean = false;
  user: any = '';
  twoTimesEnrolled = false;

  // ------Table Source-----------
  userDataSource: MatTableDataSource<any>;
  eligibleDataSource: MatTableDataSource<EligibleData>;
  paymentDataSource: MatTableDataSource<PaymentData>;
  transferDataSource: MatTableDataSource<TransferData>;
  userColumns = ["firstName", "spinId", "lastModifiedDate"];
  // enrolledUserColumns = ["firstName", "spinId", "lastModifiedDate", "vehiclevin", "lfsvalidity", "payduecdandLfspaycd"];
  enrolledUserColumns = ["firstName", "spinId", "lastModifiedDate", "vehiclevin", "lfsvalidity", "payduecdandLfspaycd","showTick"];
  // eligibleColumns = ["FIRST_NAME", "SPIN_ID", "cert_level"];
  eligibleColumns = ["FIRST_NAME", "SPIN_ID","mar_master", "total_credits", "lexus_essentials","tenure_months"];
  paymentColumns = ['name', 'spinId', 'paymentNo', 'paymentPeriod'];
  transferColumns = ["name", "spin_id", "enrolled_dt"];

  //------------- Paginator and Sort @ViewChild-----------------
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  @ViewChild('transferSort', { read: MatSort }) transferSort: MatSort;
  @ViewChild('transferPaginator', { read: MatPaginator }) transferPaginator: MatPaginator;

  // =========Accordion Panel State==============
  panelOpenState = false;

  //---------Variable Declarations ----------------------->
  totalTableData: number = 0;
  customFilter: boolean = false;
  totalClick: boolean = true;
  pendingClick: boolean = false;
  customClick: boolean = false;
  selected_count: any;
  selected_statusfilter: { name: string; count: number; }[];
  statusfilters = [
    {
      name: 'DELETED',
      selected: false
    },
    {
      name: 'ABANDONED',
      selected: false
    }];
  pendingfilters = [
    {
      name: 'ALL',
      selected: false
    },
    {
      name: 'PENDING WITH ASSOCIATE',
      selected: false
    },
    {
      name: 'PENDING WITH PRINCIPAL/GM',
      selected: false
    },
    {
      name: 'PENDING UPLOAD',
      selected: false
    }];
  filter_status: string;
  filter_array: any = [];
  filterTab: boolean = false;
  eligible: boolean = true;
  eligible_filter_array: any = [];
  newTab: boolean = true;
  selectedIndex: number = 2;
  openAccordian: boolean = true;
  allExpandState: boolean = true;
  hideTooltip: boolean = false;
  pendingfilter: boolean = false;
  checkedCount: number = 0;
  enrollmentResponse: any;
  USERDATA: EnrollData[] = [];
  ENROLLDATA: EnrollData[] = [];
  PENDINGDATA: EnrollData[] = [];
  DRAFTDATA: EnrollData[] = [];
  ABANDONEDDATA: EnrollData[] = [];
  DELETEDDATA: EnrollData[] = [];
  pendingTableData: number = 0;
  draftTableData: number = 0;
  abandonedTableData: number = 0;
  deletedTableData: number = 0;
  nodata: boolean = false;
  tableLoading: boolean = false;
  eligibleResponse: any;
  ELIGIBLITY: EligibleData[] = [];
  ELIGIBLEDATA: EligibleData[] = [];
  NONELIGIBLEDATA: EligibleData[] = [];
  eligibleTableData: number = 0;
  nonEligibleTableData: number = 0;
  eligibleTableLoading: boolean = false;
  paymentTableLoading: boolean = false;
  paymentNoData: boolean = false;
  eligibleNoData: boolean = false;
  showExtraClass: boolean = true;
  getFilterServer: any = [];
  filterPressed: boolean = false;
  efilterPressed: boolean = false;
  enrollExport: any = [];
  eligibleExport: any = [];
  exportEnroll: boolean;
  exportPayment: boolean = false;
  showSpinner: Boolean;

  // DEALERSHIP VARIABLES //

  dealershipMessage = 'Your Lexus dealership ' + this.appService.dealerName + ' (' + this.appService.dc + ') is not yet enrolled in the Lease My Lexus Program.';
  //dealershipMessage = 'Your Lexus dealership ABSURY (12345) is not yet enrolled in the Lease My Lexus Program.';
  dealershipEnrollment = false;
  dealershipDraft = false;
  dealershipPending = false;
  dealershipUpload = false;
  status = '';
  dealershipSuccessMessage = '';
  principalDetailResponse: any = '';
  gmDetailResponse: any = '';
  principalEmailId = '';
  principalFullname = '';
  principalSpinid = '';
  generalManagerEmailId = '';
  generalManagerFullname = '';
  generalManagerSpinid = '';
  associateEmailId = '';
  associateFullname = '';
  invalidUserEmail = [];
  disableMailButton = false;
  switchToPrincipal = false;
  signIframeLoading = true;
  iframeOpenedOnce = false;
  dealershipEnrollmentNo = '';
  dealershipEnvelopeId = '';
  closedIframe = false;
  enrollmentCountDown = 30;
  enrollmentMsg = "Dealer enrollment is in-progress. Please wait for " + this.enrollmentCountDown + " seconds.";
  oldDealer: String = '';
  transferEnroll: boolean = false;
  transferTableLoading: boolean = false;
  transferTableData: number = 0;
  transferNoData: boolean = false;
  transferExport: any = [];
  TRANSFERDATA: TransferData[] = [];
  transferResponse: any;
  tfilterPressed: boolean = false;
  // Notification 
  showNotifyBanner: boolean = false;
  showBannerLease: boolean = false;
  showBannerCert: boolean = false;
  //notificationBanner: string = 'Certification details will be loaded within the first week of the month.'
  notificationBanner2: string;
  notificationBanner3: string;
  asOfDtArr: any = [];
  asOfDtArrLs: any = [];
  updatedOn: string = ''
  //Table Variables
  paymentHistoryResponse: any = '';
  PAYMAINDATA: any = [];
  PAYMENTDATA: PaymentData[] = [];
  paymentTotalData: number = 0;
  NoExcelData: boolean = false;
  sendFormToPrincipal: boolean = false;

  @Input('matTooltipPosition')
  position: TooltipPosition = 'below';
  searchPosition: TooltipPosition = 'right';

  sectionName: string;
  pfilterPressed: boolean = false;
  payExport: any[] = [];
  certificationResponse: any = '';
  enrolledTabName: string = 'ENROLLED DATE';
  dealerDetailsResponse: any = {};
  fullAddress: any = '';
  emailSendBtn: boolean = false;
  dealerName: string = '';
  dealerInsertErrorMessage: string = '';
  ErrorDealership: boolean = false;
  selectPrincipal: boolean = true;
  selectGM: boolean = false;
  selectedOfficialEmail: any = '';
  hidePrincipal: boolean = true;
  hideGm: boolean = true;
  principalOrGm = '';
  gmName = '';
  principalName = '';
  selectedSpinId = '';
  selectedPrincipalEmailId = '';
  selectedGmEmailId = '';
  emailButton = 'E-SIGN';
  dealerFormSentTo = '';
  pendingMailResend: boolean = false;
  disableResendButton: boolean = false;

  //Manual sign variables
  progressValue = 0;
  uploadAccordian = true;
  showFormImage = true;
  emailMessage = 'Enrollment form will be emailed for signature to : '
  pdfLoading = true;
  loading: boolean = true;
  countValue = 0;
  enrolformNotFound: boolean = false;
  expandFormNotFound: boolean = false;
  browseDisable: boolean = true;
  principalCheckbox: boolean = false;
  assocaiteCheckbox: boolean = false;
  uploadFormButton: boolean = false;
  checkbox: boolean = false;
  fileToUpload: File = null;
  fileName: string;
  successMessage: boolean = false;
  sentToAssociate = false;
  message = "";
  pdfUrl = '';
  envelopeId = '';
  pdfError: boolean = false;
  errorMsg: string = '';
  pdfHref: string = '';
  checkChrome: boolean;
  config: any;
  fileUrl: any;
  fileBlob: any;
  dasboardTab: boolean = false;
  disablePrint: boolean = true;
  sasTokenResponse: any = '';
  errorUploadForm: boolean = false;
  draftdealerData: any = '';
  enrollmentform: string = '';

  // valid job code list
  validJobCodeList: any = ['004','005','006','007','011','012','015','017','020','023','024','027','028','029','032','035','038','039','040','041','053','054','055','065','099','136','162','031','043']
  jobElig: boolean;
  certElig: boolean;

  constructor(private fb: FormBuilder, private dashboardService: DashboardService, public appService: CommonService,
    private router: Router, private excelService: ExcelService, private enrollService: EnrollService, private appComponent: AppComponent,
    private blob: BlobService) {
    var PageUrl = window.location.href;
    //this.appService.dc = '12345';
    this.showSpinner = true;
    if (PageUrl.indexOf('/dashboard') != -1) {
      this.onLoadData();
      // var d = new Date();
      // var day = d.getDate();
      // if (day >= 1 && day < 7) {
      //   this.showNotifyBanner = true;
      // } else {
      //   this.showNotifyBanner = false;
      // }
    }
  }

  async onLoadData() {
    await this.enrollService.getPrincipalDetails(this.appService.dc).toPromise().then(async response => {
      console.log('Principal Details', response.PrincipalDetails.Result);
      this.principalDetailResponse = response.PrincipalDetails.Result;
      if (response.PrincipalDetails.Result.length != 0) {
        this.principalEmailId = response.PrincipalDetails.Result[0].USER_EMAIL;
        this.principalFullname = response.PrincipalDetails.Result[0].FULL_NAME;
        this.principalSpinid = response.PrincipalDetails.Result[0].SPIN_ID;
      } else {
        this.principalEmailId = '';
        this.principalFullname = '';
        this.principalSpinid = '';
      }
    });
    await this.enrollService.getGMDetails(this.appService.dc).toPromise().then(async response => {
      console.log('General Manager Details', response.GeneralManagerDetails.Result);
      this.gmDetailResponse = response.GeneralManagerDetails.Result;
      if (response.GeneralManagerDetails.Result.length != 0) {
        this.generalManagerEmailId = response.GeneralManagerDetails.Result[0].USER_EMAIL;
        this.generalManagerFullname = response.GeneralManagerDetails.Result[0].FULL_NAME;
        this.generalManagerSpinid = response.GeneralManagerDetails.Result[0].SPIN_ID;
      } else {
        this.generalManagerEmailId = '';
        this.generalManagerFullname = '';
        this.generalManagerSpinid = '';
      }
    });
    await this.getDealerDetails();

    this.dashboardService.checkDealershipEnrolled(this.appService.dc).subscribe((result: any) => {
      console.log('Dealer Enrollment', result);
      if (result.DealerEnrollmentCheck.Result.length > 0 && result.DealerEnrollmentCheck.Result[0].enrollmentStatus == 'ENROLLED') {
        this.appService.dealershipEnrollment = false; //set false
        this.pendingMailResend = false;
        this.status = '';
        this.dealerFormSentTo = result.DealerEnrollmentCheck.Result[0].sentTo;
        this.dealershipEnrollmentNo = result.DealerEnrollmentCheck.Result[0].enrollmentNo;
        this.appService.dealershipEnrollmentNo = this.dealershipEnrollmentNo;
        this.appService.dealershipFormName = result.DealerEnrollmentCheck.Result[0].fileName;
      } else if (result.DealerEnrollmentCheck.Result.length > 0 && result.DealerEnrollmentCheck.Result[0].enrollmentStatus != 'ENROLLED') {
        this.appService.dealershipEnrollment = true;
        //debugger;
        this.appService.dealershipStatus = result.DealerEnrollmentCheck.Result[0].enrollmentStatus;
        this.dealershipEnrollmentNo = result.DealerEnrollmentCheck.Result[0].enrollmentNo;
        this.dealershipEnvelopeId = result.DealerEnrollmentCheck.Result[0].envelopeId;
        this.dealerFormSentTo = result.DealerEnrollmentCheck.Result[0].sentTo;
        this.principalOrGm = result.DealerEnrollmentCheck.Result[0].principalOrGmName;
        this.appService.dealershipEnrollmentNo = this.dealershipEnrollmentNo;
        this.appService.dealershipFormName = result.DealerEnrollmentCheck.Result[0].fileName;
        this.dealershipEnrollment = true;
        this.dealershipMessage = 'Your Lexus dealership ' + this.dealerName + ' (' + this.appService.dc
          + ') is not yet enrolled in the Lease My Lexus Program.';
        //this.dealershipMessage = 'Your Lexus dealership ABSURY (12345) is not yet enrolled in the Lease My Lexus Program.';
        console.log(this.principalFullname, '\n address ::: ' + this.fullAddress);
        if (this.principalOrGm != '' || this.principalOrGm != undefined || this.principalOrGm != null) {
          this.makePdf(this.principalOrGm, this.fullAddress);
        } else { this.makePdf('', this.fullAddress); }

        if (this.appService.dealershipStatus != null) {
          this.status = this.appService.dealershipStatus;
        }
        if (this.appService.dealershipStatus == 'DRAFT') {
          this.dealershipDraft = true; // set true
          this.dealershipPending = false;
          this.pendingMailResend = false;
          this.dealershipUpload = false;
          if (this.oldDealer != '' && this.oldDealer != null) {
            this.transferEnroll = true;
          }
          this.disableMailButton = false;
          this.dealershipMessage = 'Enrollment form has been generated for ' + this.dealerName + '. Enrollment ID: ' + this.dealershipEnrollmentNo;
          this.status = 'DRAFT';
          this.emailButton = 'E-SIGN';
        }
        if (this.appService.dealershipStatus == 'PENDING WITH PRINCIPAL') {
          this.dealershipDraft = false;
          this.dealershipPending = true;
          this.pendingMailResend = true;
          this.dealershipUpload = false;
          if (this.oldDealer != '' && this.oldDealer != null) { this.transferEnroll = true; }
          this.disableMailButton = true;
          this.dealershipMessage = 'Enrollment form has been sent to ' + this.principalOrGm + ' (Principal) for signature. Enrollment ID: '
            + this.dealershipEnrollmentNo;
          this.status = 'PENDING WITH PRINCIPAL';
          this.pendingMailResend = true;
          this.emailButton = 'E-SIGN (RESEND)';
        }
        if (this.appService.dealershipStatus == 'PENDING WITH GM') {
          this.dealershipDraft = false;
          this.pendingMailResend = true;
          this.dealershipPending = true;
          this.dealershipUpload = false;
          if (this.oldDealer != '' && this.oldDealer != null) {
            this.transferEnroll = true;
          }
          this.disableMailButton = true;
          this.dealershipMessage = 'Enrollment form has been sent to ' + this.principalOrGm + ' (General Manager) for signature. Enrollment ID: '
            + this.dealershipEnrollmentNo;
          this.status = 'PENDING WITH GM';
          this.pendingMailResend = true;
          this.emailButton = 'E-SIGN (RESEND)';
        }
        if (this.appService.dealershipStatus == 'PENDING UPLOAD') {
          this.dealershipDraft = false;
          this.pendingMailResend = false;
          this.dealershipPending = true;
          this.dealershipUpload = true;
          if (this.oldDealer != '' && this.oldDealer != null) {
            this.transferEnroll = true;
          }
          this.disableMailButton = true;
          this.dealershipMessage = 'Enrollment form has been printed for manual signature process. Enrollment ID: '
            + this.dealershipEnrollmentNo;
          this.status = 'PENDING UPLOAD';
        }
      } else if (result.DealerEnrollmentCheck.Result.length == 0) {
        this.dealershipEnrollment = true;
        this.appService.dealershipEnrollment = true;
        this.status = '';
      }
      this.showSpinner = false;
      this.nodata = true;
      this.tableLoading = true;
      this.eligibleTableLoading = true;
      this.eligibleNoData = true;
      if (result.DealerEnrollmentCheck.Result.length != 0 && result.DealerEnrollmentCheck.Result[0].enrollmentStatus == 'ENROLLED') {
        this.dashboardService.getEnrollmentData().subscribe((response: any) => {
          this.enrollmentResponse = [];
          this.ENROLLDATA = [];
          this.PENDINGDATA = [];
          this.DRAFTDATA = [];
          this.DELETEDDATA = [];
          this.ABANDONEDDATA = [];
          this.USERDATA = [];
          this.enrollmentResponse = response.EnrollemtDashboard.Result;
          for (let i = 0; i < this.enrollmentResponse.length; i++) {
            const lstUpdtDt = this.enrollmentResponse[i].lst_updt_dt;
            if (lstUpdtDt != null) {
              const onlyLstDt = lstUpdtDt.substring(0, 10);
              const DateHyp = onlyLstDt.replace(/-/g, '\/');
              this.asOfDtArrLs.push(DateHyp);
            }
          }
          const maxDateLs = new Date(Math.max(...this.asOfDtArrLs.map(date => Date.parse(date))));
          if (maxDateLs != null && maxDateLs != undefined && maxDateLs.toLocaleDateString() != 'Invalid Date') {
            this.showBannerLease = true;
            this.notificationBanner3 = 'Lease data is up to date as of ' + maxDateLs.toLocaleDateString() + '. It takes 1-2 weeks for the new or updated lease information to reflect in LML.';
          }
          [].push.apply(this.USERDATA, this.enrollmentResponse);
          this.tableLoading = false;
          // filter
          if (this.USERDATA) {
            for (let i = 0; i < this.USERDATA.length; i++) {
              if (this.USERDATA[i].enrollmentStatus.toUpperCase() != 'ENROLLED' || this.USERDATA[i].vehiclevin == null || this.USERDATA[i].vehiclevin == "") {
                this.USERDATA[i].vehiclevin = 'NA';
              }
              if (this.USERDATA[i].enrollmentStatus.toUpperCase() != 'ENROLLED' || this.USERDATA[i].lfsvalidity == null || this.USERDATA[i].lfsvalidity == "") {
                this.USERDATA[i].lfsvalidity = 'NA';
              } else {this.USERDATA[i].lfsvalidity = (this.USERDATA[i].lfsvalidity).substring(0,10);}
              if (this.USERDATA[i].enrollmentStatus.toUpperCase() != 'ENROLLED' || this.USERDATA[i].payduecdandLfspaycd == null || this.USERDATA[i].payduecdandLfspaycd == ""
                || this.USERDATA[i].payduecdandLfspaycd == " ():NA") {
                this.USERDATA[i].payduecdandLfspaycd = 'NA';
              }
              this.USERDATA[i].lastModifiedDate = (this.USERDATA[i].lastModifiedDate).substring(0,10);
              
              if (this.USERDATA[i]["maritzCert"] == null || this.USERDATA[i]["maritzCert"] == 'N') 
              {this.USERDATA[i]["maritzCert"] = 'N'; this.USERDATA[i]["maritzCertFull"] = 'Not Certified';}
              if (this.USERDATA[i]["eligStat"] == null || this.USERDATA[i]["eligStat"] == '') 
              {this.USERDATA[i]["eligStat"] = 'No'; this.USERDATA[i]["totalCred"] = 'NA';}
              else if (this.USERDATA[i]["maritzCert"] == 'M') {this.USERDATA[i]["maritzCertFull"] = 'Master';}
              else if (this.USERDATA[i]["maritzCert"] == 'S') {this.USERDATA[i]["maritzCertFull"] = 'Senior';}
              else if (this.USERDATA[i]["maritzCert"] == 'C') {this.USERDATA[i]["maritzCertFull"] = 'Certified';}

              let payduecdFull = (this.USERDATA[i]["maxPayduecd"]).concat('-01');

              let paynum;
              if (this.USERDATA[i].payduecdandLfspaycd != 'NA') {
                paynum = Number((this.USERDATA[i].payduecdandLfspaycd).substring(14,16));
              } else { paynum = 0;}
              if (this.USERDATA[i]["enrolledDt"] == null) {this.USERDATA[i]["enrolledDt"] = ''}
              this.USERDATA[i]["enrolOrRenewalDt"] = new Date((this.USERDATA[i]["enrolledDt"]).replace(/-/g, '\/')).toLocaleDateString('en-US');
              let myDt = new Date(this.USERDATA[i]["enrolOrRenewalDt"]);
              // job code check
              if (this.validJobCodeList.includes(this.USERDATA[i]["jobCode"]) ) {
                this.USERDATA[i]["jobElig"] = true;
              } else {this.USERDATA[i]["jobElig"] = false;}
              // cert check if lfs pay number is 12 or enrol/renewal is 2 years ago
              let twoYrsAgo = new Date(); twoYrsAgo.setFullYear(new Date().getFullYear() - 2);
              let payExpiryTime = new Date(new Date(myDt).setFullYear(new Date(myDt).getFullYear() + 2));
              payExpiryTime.setDate(1); payExpiryTime.setHours(-1); // to get last date of prev month
              this.USERDATA[i]["payExpiryTime"] = payExpiryTime.toLocaleDateString('en-US');
              if ((this.USERDATA[i].payduecdandLfspaycd).substring(14,16) == '12' || new Date(this.USERDATA[i]["enrolledDt"]) < twoYrsAgo) {
                if (this.USERDATA[i]["eligStat"] === 'Yes' || this.USERDATA[i]["maritzCert"] === 'M' ) {
                  this.USERDATA[i]["certElig"] = true;
                  this.USERDATA[i]["remainingPays"] = 12; //no of new pays after auto enrol.
                  if (this.USERDATA[i]["eligStat"] === 'No' && this.USERDATA[i]["maritzCert"] !== 'M') {
                    this.USERDATA[i]["certEligIndi"] = false;
                  } else {this.USERDATA[i]["certEligIndi"] = true;}
                } else { 
                  this.USERDATA[i]["certElig"] = false;
                  this.USERDATA[i]["remainingPays"] = 0;
                  if (this.USERDATA[i]["eligStat"] !== 'Yes' && this.USERDATA[i]["maritzCert"] !== 'M') {
                    this.USERDATA[i]["certEligIndi"] = false;
                  } else {this.USERDATA[i]["certEligIndi"] = true;}
                }
              } else {
                this.USERDATA[i]["certElig"] = true;
                if (payExpiryTime > new Date(new Date().setFullYear(new Date().getFullYear() + 1))) {
                  this.USERDATA[i]["remainingPays"] = 12 - paynum;
                } else {
                  let paysRemaining = 0; let nowMonth = new Date(); let nowTime;
                  let payMonthDiff = moment.duration(moment(new Date()).diff(moment(payduecdFull)));
                  if (payMonthDiff["_data"]["months"] == 2) {nowTime = new Date(new Date().setMonth(new Date().getMonth() - 1))}
                  else {nowTime = new Date()} 
                  let timeDiff = moment.duration(moment(payExpiryTime).diff(moment(nowTime)))
                  let countUpTo = timeDiff["_data"]["months"];
                  for (let a=0; a<countUpTo+1 && paysRemaining <= 12; a++) {
                    paysRemaining ++
                  }
                  if (paysRemaining + paynum > 12) {
                    this.USERDATA[i]["remainingPays"] = 12 - paynum;
                  } else { this.USERDATA[i]["remainingPays"] = paysRemaining; }
                }
                if (this.USERDATA[i]["eligStat"] == 'No' && this.USERDATA[i]["maritzCert"] != 'M') {
                  this.USERDATA[i]["certEligIndi"] = false;
                } else {this.USERDATA[i]["certEligIndi"] = true;}
              }
              // lfs check
              // if (this.USERDATA[i].lfsvalidity == 'NA' || new Date (this.USERDATA[i].lfsvalidity) < new Date(new Date().setMonth(new Date().getMonth()+1, 1)) ) {
                if (this.USERDATA[i].lfsvalidity == 'NA' || new Date(new Date(this.USERDATA[i].lfsvalidity).setMonth(new Date(this.USERDATA[i].lfsvalidity).getMonth()+1, 0)) < new Date(new Date(payduecdFull).setMonth(new Date(payduecdFull).getMonth()+1, 1)) ) {
                this.USERDATA[i]["lfsElig"] = false;
              } else { this.USERDATA[i]["lfsElig"] = true; }

              if (this.USERDATA[i].enrollmentStatus.toUpperCase() != 'ENROLLED') {
                this.USERDATA[i]["remainingPays"] = 'NA';
                this.USERDATA[i]["payExpiryTime"] = 'NA';
                this.USERDATA[i]["enrolOrRenewalDt"] = 'NA';
              }

              if (this.USERDATA[i]["jobElig"] === true && this.USERDATA[i]["lfsElig"] === true && this.USERDATA[i]["certElig"] === true ) {
                this.USERDATA[i]["showTick"] = 'Elig';
              } else { this.USERDATA[i]["showTick"] = 'NotElig'}
              
              this.USERDATA[i].enrollmentStatus = this.USERDATA[i].enrollmentStatus.toUpperCase();
              if (this.USERDATA[i].enrollmentStatus.toUpperCase() == 'ENROLLED') {
                this.ENROLLDATA.push(this.USERDATA[i]);
              } else if (this.USERDATA[i].enrollmentStatus.toUpperCase().indexOf('PENDING') != -1) {
                this.PENDINGDATA.push(this.USERDATA[i]);
              } else if (this.USERDATA[i].enrollmentStatus.toUpperCase() == 'DRAFT') {
                this.DRAFTDATA.push(this.USERDATA[i]);
              } else if (this.USERDATA[i].enrollmentStatus.toUpperCase() == 'DELETED') {
                this.DELETEDDATA.push(this.USERDATA[i]);
              } else {
                this.ABANDONEDDATA.push(this.USERDATA[i]);
              }
            }
            this.userDataSource = new MatTableDataSource(this.ENROLLDATA);
            this.totalTableData = this.ENROLLDATA.length;
            if (this.totalTableData > 0 || this.pendingTableData > 0 || this.draftTableData > 0 || this.deletedTableData > 0 || this.abandonedTableData > 0) {
              this.nodata = false;
            } else {
              this.nodata = true;
            };
            this.pendingTableData = this.PENDINGDATA.length;
            this.draftTableData = this.DRAFTDATA.length;
            this.abandonedTableData = this.ABANDONEDDATA.length;
            this.deletedTableData = this.DELETEDDATA.length;
            this.userDataSource.paginator = this.paginator.toArray()[0];
            this.userDataSource.sort = this.sort.toArray()[0];
            this.enrollFilter();
            this.select(2);
            if (this.filter_array.length != 0) {
              for (let i = 0; i < this.filter_array.length; i++) {
                if (this.filter_array[i].name == 'ABANDONED') {
                  this.filter_array[i].count = this.abandonedTableData;
                }
                else {
                  this.filter_array[i].count = this.deletedTableData;
                }
              }
            }
          }
        },
          err => {
            // console.log(err)
            this.totalTableData = 0;
            this.pendingTableData = 0;
            this.draftTableData = 0;
            this.deletedTableData = 0;
            this.abandonedTableData = 0;
            this.nodata = true;
            this.tableLoading = false;
            this.showSpinner = false;
          });

        this.dashboardService.getEligibleData().subscribe((response: any) => {
          this.eligibleResponse = [];
          this.ELIGIBLEDATA = [];
          this.NONELIGIBLEDATA = [];
          this.ELIGIBLITY = [];
          this.eligibleResponse = response.EnrollmentSummaryDetail.EnrollmentDetail;
          [].push.apply(this.ELIGIBLITY, this.eligibleResponse);
          if (this.ELIGIBLITY) {
            for (let i = 0; i < this.eligibleResponse.length; i++) {
              const dates = (this.eligibleResponse[i].as_of_date);
              if (dates != null) {
                const onlyDt = dates.substring(0, 10);
                const DtHyp = onlyDt.replace(/-/g, '\/');
                this.asOfDtArr.push(DtHyp);
              }
              const maxDate = new Date(Math.max(...this.asOfDtArr.map(date => Date.parse(date))));
              if (maxDate != null && maxDate != undefined && maxDate.toLocaleDateString() != 'Invalid Date') {
                this.showBannerCert = true;
                this.notificationBanner2 = 'Eligibility details are up to date as of ' + maxDate.toLocaleDateString();
              }
              // if (this.eligibleResponse[i].Status == "True" && this.eligibleResponse[i].cert_level != 'Senior') {
              if (this.eligibleResponse[i].Status == "True" || this.eligibleResponse[i].mar_master == "Master") {
                // console.log('elig guys :: ', this.eligibleResponse[i]);
                this.ELIGIBLEDATA.push(this.eligibleResponse[i]);
                this.eligibleTableData++;
              }
              // else if (this.eligibleResponse[i].Status == "True" && this.eligibleResponse[i].cert_level == 'Senior') {
              //   this.enrollService.seniorEligibleCheck(this.eligibleResponse[i].SPIN_ID).subscribe((seniorEligibleResponse: any) => {
              //     if (seniorEligibleResponse.SeniorEligibleCheck.Result[0] != undefined && seniorEligibleResponse.SeniorEligibleCheck.Result[0].length != 0 && seniorEligibleResponse.SeniorEligibleCheck.Result[0] != '') {
              //       var isSeniorEligible = this.appService.filterSeniorEligibleDashboard(seniorEligibleResponse.SeniorEligibleCheck.Result[0]);
              //       if (isSeniorEligible) {
              //         this.eligibleResponse[i].cert_level = 'Senior Eligible';
              //         this.ELIGIBLEDATA.push(this.eligibleResponse[i]);
              //         this.eligibleTableData++;
              //       } else {
              //         this.eligibleResponse[i].cert_level = 'Senior';
              //         this.NONELIGIBLEDATA.push(this.eligibleResponse[i]);
              //         this.nonEligibleTableData++;
              //       }
              //     } else {
              //       this.eligibleResponse[i].cert_level = 'Senior';
              //       this.NONELIGIBLEDATA.push(this.eligibleResponse[i]);
              //       this.nonEligibleTableData++;
              //     }
              //   });
              // } 
              else {
                // console.log('not elig guys :: ', this.eligibleResponse[i]);
                this.NONELIGIBLEDATA.push(this.eligibleResponse[i]);
                this.nonEligibleTableData++;
              }
            }
            this.eligibleDataSource = new MatTableDataSource(this.ELIGIBLEDATA);
            this.eligibleTableLoading = false;
            //this.eligibleTableData = this.ELIGIBLEDATA.length;
            if (this.eligibleTableData > 0 || this.nonEligibleTableData > 0) {
              this.eligibleNoData = false;
            } else {
              this.eligibleNoData = true;
            }
            //this.nonEligibleTableData = this.NONELIGIBLEDATA.length;
            this.eligibleDataSource.paginator = this.paginator.toArray()[1];
            this.eligibleDataSource.sort = this.sort.toArray()[1];
          }
        },
          err => {
            // console.log(err)
            this.nonEligibleTableData = 0;
            this.eligibleTableData = 0;
            this.eligibleNoData = true;
            this.eligibleTableLoading = false;
          });
        this.getPaymentHistoryDetail();
        this.getFilterServer = this.appService.getFilterContent();
      } else {
        this.transferNoData = true;
        this.transferTableLoading = true;
        this.nonEligibleTableData = 0;
        this.eligibleTableData = 0;
        this.eligibleNoData = true;
        this.eligibleTableLoading = false;
        this.totalTableData = 0;
        this.pendingTableData = 0;
        this.draftTableData = 0;
        this.deletedTableData = 0;
        this.abandonedTableData = 0;
        this.nodata = true;
        this.tableLoading = false;
        this.paymentNoData = true;
        this.paymentTotalData = 0;
        this.paymentTableLoading = false;
        this.dashboardService.getTransferData(this.oldDealer).subscribe((response: any) => {
          this.transferResponse = [];
          this.TRANSFERDATA = [];
          this.transferResponse = response.TransferSummaryDetail.TransferDetail;
          [].push.apply(this.TRANSFERDATA, this.transferResponse);
          for (let i = 0; i < this.transferResponse.length; i++) {
            this.TRANSFERDATA[i].name = this.transferResponse[i].NAME;
            this.TRANSFERDATA[i].spin_id = this.transferResponse[i].SPIN_ID;
            this.TRANSFERDATA[i].enrolled_dt = this.transferResponse[i].ENROLLED_DATE;
            this.transferTableData++;
          }
          this.transferDataSource = new MatTableDataSource(this.TRANSFERDATA);
          this.transferTableLoading = false;
          if (this.transferTableData > 0) {
            this.transferNoData = false;
          } else {
            this.transferNoData = true;
          }
          this.transferDataSource.paginator = this.transferPaginator;
          this.transferDataSource.sort = this.transferSort;
        },
          err => {
            // console.log(err)
            this.transferTableData = 0;
            this.transferNoData = true;
            this.transferTableLoading = false;
          });
      }
    });
  }

  ngOnInit() {
    console.log('pc dash', this.appService.role);
    if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' ||
      this.appService.role == 'Area Admin' || this.appService.role == 'Billing' || this.appService.role == 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }

    // window.scrollTo(0, 0);
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);

    if (isIE) { // IE Browser
      this.checkChrome = false;
    }
    else {
      this.checkChrome = true;
    }

    this.appService.setEnrollUserData([]);
    this.usersForm = this.fb.group({
      userInput: null
    });

    if (this.getFilterServer.length != 0) {
      this.addTabServer(this.getFilterServer)
    }

    // if (this.appService.isPrincipal) {
    this.dashboardService.getAssociateDetails().subscribe(response => {
      console.log('associate', response);
      if (response.PrincipalDetails.Result.length != 0) {
        this.associateFullname = response.PrincipalDetails.Result[0].FIRST_NAME + ' ' + response.PrincipalDetails.Result[0].LAST_NAME;
        // this.associateFullname = response.PrincipalDetails.Result[0].FULL_NAME
        this.associateEmailId = response.PrincipalDetails.Result[0].USER_EMAIL;
        if (this.associateEmailId == '' || this.associateEmailId == null) {
          this.associateEmailId = 'LexusMasterLease@lexus.com';
        }
      }
    });
    // }
  }

  filterId() {
    if (this.spinId != undefined) {
      this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, "");
      if (this.spinId.length >= 2) {
        // console.log(this.spinId);
        this.filteredUsers = [];
        this.isLoading = true;
        this.dashboardService.autoSearch(this.appService.dc, this.spinId).subscribe((result) => {
          console.log(result);
          this.filteredUsers = result.EnrolmentMaster.EnrolmentDtl;
          console.log(this.filteredUsers);
          for (let i = 0; i < this.filteredUsers.length; i++) {
            this.filteredUsers[i].firstName = this.filteredUsers[i].FIRST_NAME;
            this.filteredUsers[i].lastName = this.filteredUsers[i].LAST_NAME;
            this.filteredUsers[i].spinId = this.filteredUsers[i].SPIN_ID;
            this.filteredUsers[i].fullName = this.filteredUsers[i].firstName + ' ' + this.filteredUsers[i].lastName;
          }
          this.isLoading = false;
        });
      }
    }
  }

  selectUser(user: any) {
    this.appService.navigationFromDashboard = true;
    this.showSpinner = this.appService.navigationFromDashboard;
    console.log('in select', user.spinId);
    //this.spinId = user.spinId;
    this.spinId = user.firstName + ' ' + user.lastName;
    this.dashboardService.getStaffDetails(user.spinId, this.appService.dc).subscribe((result: any) => {
      var dc = user.DEALER_CODE;
      if (dc == undefined) {
        dc = this.appService.dc;
      }
      this.user = [];
      this.user = result.EnrollmentDetail.EnrollDetail;
      var eligibleUser = false;
      if (this.user == undefined || this.user == null) {
        this.user = [];
        this.appService.navigationFromDashboard = false;
        this.showSpinner = this.appService.navigationFromDashboard;
      } else {
        loop1:
        for (let i = 0; i < this.user.length; i++) {
          loop2:
          for (let j = 0; j < this.appService.jobCodeList.length; j++) {
            if (Number(this.user[i].JOB_CODE) == Number(this.appService.jobCodeList[j])) {
              eligibleUser = true;
              console.log('staff master error');
              this.user = this.user[i];
              break loop1;
            }
          }
        }

        this.user.eligibleUser = eligibleUser;
        this.user.enrollmentForm = this.appService.dashtoenrollEnrollmentForm.enrollmentForm;

        console.log(this.user.eligibleUser);
        if (!eligibleUser) {
          this.user = result.EnrollmentDetail.EnrollDetail[0];
          this.user.employmentStatus = 'Ineligible Job Code'
        }
        if (eligibleUser) {
          this.user.employmentStatus = this.user.employee_status.toLowerCase();
        }
        this.user.firstName = this.user.first_name.toUpperCase();
        if (this.user.firstName == null || this.user.firstName == undefined || this.user.firstName.toLowerCase() == 'null') {
          this.user.firstName = '';
        }
        this.user.middleName = this.user.Middle_Name.toUpperCase();
        if (this.user.middleName == null || this.user.middleName == undefined || this.user.middleName.toLowerCase() == 'null') {
          this.user.middleName = '';
        }
        this.user.lastName = this.user.Last_Name.toUpperCase();
        if (this.user.lastName == null || this.user.lastName == undefined || this.user.lastName.toLowerCase() == 'null') {
          this.user.lastName = '';
        }
        this.user.spinId = this.user.spin_id.toUpperCase();
        if (this.user.spinId == null || this.user.spinId == undefined || this.user.spinId.toLowerCase() == 'null') {
          this.user.spinId = '';
        }

        if (this.user.employmentStatus == null || this.user.employmentStatus == undefined || this.user.employmentStatus.toLowerCase() == 'null') {
          this.user.employmentStatus = '';
        }
        this.user.contact = this.user.contact_phone_no;
        if (this.user.contact == null || this.user.contact == undefined || this.user.contact.toLowerCase() == 'null') {
          this.user.contact = '';
        }
        this.user.email = this.user.user_email;
        if (this.user.email == null || this.user.email == undefined || this.user.email.toLowerCase() == 'null') {
          this.user.email = '';
        }
        this.user.jobTitle = this.user.job_description.toUpperCase();
        if (this.user.jobTitle == null || this.user.jobTitle == undefined || this.user.jobTitle.toLowerCase() == 'null') {
          this.user.jobTitle = '';
        }
        this.user.tenure = this.user.tenure + ' Months';
        if (this.user.tenure == null || this.user.tenure == undefined || this.user.tenure.toLowerCase() == 'null') {
          this.user.tenure = '';
        }
        this.spinId = this.user.firstName.toUpperCase() + ' ' + this.user.lastName.toUpperCase();
        this.dashboardService.getCertificationDetails(this.user.spinId, dc).subscribe(async (result1: any) => {
          console.log('Certification Details', result1);
          if (result1.EnrollmentMaritzDetail.MaritzDetail.length != 0) {
            this.certificationResponse = result1.EnrollmentMaritzDetail.MaritzDetail[result1.EnrollmentMaritzDetail.MaritzDetail.length - 1];
          } else {
            this.certificationResponse = undefined;
          }
          console.log('cert res :', this.certificationResponse);
          if (this.certificationResponse != undefined && result1.EnrollmentMaritzDetail.MaritzDetail.length != 0) {
            // if (this.certificationResponse.cert_level == 'C') {
            //   this.user.certificationLevel = 'Certified';
            // }
            // else if (this.certificationResponse.cert_level == 'S') {
            // new check here!!
            // this.enrollService.seniorEligibleCheck(user.spinId).subscribe((seniorEligibleResponse: any) => {
            //   console.log('Senior Eligible', seniorEligibleResponse);
            //   this.user.certificationLevel = this.appService.getSeniorEligibileCheck(seniorEligibleResponse.SeniorEligibleCheck.Result)
            // })
            //   this.user.certificationLevel = await this.seniorEligibleCheck(user.spinId);
            // }
            // else if (this.certificationResponse.cert_level == 'M') {
            //   this.user.certificationLevel = 'Master';
            // }
            // else if (this.certificationResponse.cert_level == 'N') {
            //   this.user.certificationLevel = 'NA';
            // }
            // else {
            // this.user.certificationLevel = 'NA';
            console.log('mar-master: ',this.certificationResponse.mar_master,'; dex-cert: ',this.certificationResponse.cert_level);
            // this.user.certificationLevel = this.certificationResponse.cert_level;
            this.user.certificationLevel = this.certificationResponse.mar_master;
            this.user.certEligibility = this.certificationResponse.eligibility;
            this.user.totalCreds = this.certificationResponse.total_credits;
            this.user.lexusEssentials = this.certificationResponse.lexus_essentials;
            this.user.tenureMonths = this.certificationResponse.tenure_months;
            this.user.marCert = this.certificationResponse.mar_master;
            console.log('certs man: ', this.user.certificationLevel, this.user.certEligibility, 'cred', this.user.totalCreds,', c level',this.user.marCert);
            // }
            this.user.result = this.certificationResponse.result;
            if (this.user.result != 'none') {
              this.twoTimesEnrolled = true;
            }
            // user.certification_months = this.certificationResponse.certification_months;
            // if (user.certification_months == null || user.certification_months == undefined || String(user.certification_months).toLowerCase() == 'null') {
            //     user.certification_months = '';
            // }
            user.totalCreds = this.certificationResponse.total_credits;
            if (user.totalCreds == null || user.totalCreds == undefined || String(user.totalCreds).toLowerCase() == 'null') {
              user.totalCreds = '';
            }
            this.user.certifiedYear = this.certificationResponse.certified_year;
            if (this.user.certifiedYear == null || this.user.certifiedYear == undefined || String(this.user.certifiedYear).toLowerCase() == 'null') {
              this.user.certifiedYear = '';
            }
          } else {
            this.user.certificationLevel = 'NA';
          };


          this.dashboardService.getLFSDetails(this.user.spinId.substring(3)).subscribe((result2: any) => {
            console.log(result2);
            if (result2.EnrollmentLFSDetail.LFSDetail[0] != undefined && result2.EnrollmentLFSDetail.LFSDetail[0].length != 0) {
              var dateFrom = result2.EnrollmentLFSDetail.LFSDetail[0].veh_acquisition_date.substring(0, 10);
              var dateTo = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_maturity_date.substring(0, 10);
              var today: any = new Date();
              var dd: any = today.getDate();
              var mm: any = today.getMonth() + 1;
              var yyyy: any = today.getFullYear();
              if (dd < 10) {
                dd = '0' + dd
              }

              if (mm < 10) {
                mm = '0' + mm
              }

              today = yyyy + '-' + mm + '-' + dd;
              console.log(today);
              console.log(dateFrom);
              console.log(dateTo);
              var d1 = dateFrom.split("-");
              var d2 = dateTo.split("-");
              var c = today.split("-");

              var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
              var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
              var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

              // console.log(from);console.log(to);console.log(check);

              if (check > from && check < to) {
                this.user.leasedVehicle = 'Yes';
              }
              else {
                this.user.leasedVehicle = 'No';
              }
              this.user.dealerCode = result2.EnrollmentLFSDetail.LFSDetail[0].dlr_no;
              if (this.user.dealerCode == null || this.user.dealerCode == undefined || this.user.dealerCode.toLowerCase() == 'null') {
                this.user.dealerCode = '';
              }
              this.user.dealerName = result2.EnrollmentLFSDetail.LFSDetail[0].dlr_name;
              if (this.user.dealerName == null || this.user.dealerName == undefined || this.user.dealerName.toLowerCase() == 'null') {
                this.user.dealerName = '';
              } else {
                this.user.dealerName = result2.EnrollmentLFSDetail.LFSDetail[0].dlr_name.toUpperCase();
              }

              this.user.lfsNo = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_no;
              if (this.user.lfsNo == null || this.user.lfsNo == undefined || this.user.lfsNo.toLowerCase() == 'null') {
                this.user.lfsNo = '';
              }

              this.user.lfsActivationDate = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_activation_date.substring(0, 10);
              this.user.lfs_maturity_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_maturity_date.substring(0, 10);
              this.user.lfs_extension_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_extension_date.substring(0, 10);
              this.user.lfs_termination_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_termination_date.substring(0, 10);

              let lfsDate = this.user.lfsActivationDate.split("-");
              this.user.lfsActivationDate = lfsDate[1] + "/" + lfsDate[2] + "/" + lfsDate[0];
              if (this.user.lfsActivationDate == null || this.user.lfsActivationDate == undefined || this.user.lfsActivationDate.toLowerCase() == 'null') {
                this.user.lfsActivationDate = '';
              }

              let maturityDate = this.user.lfs_maturity_date.split("-");
              this.user.lfs_maturity_date = maturityDate[1] + "/" + maturityDate[2] + "/" + maturityDate[0];
              if (this.user.lfs_maturity_date == null || this.user.lfs_maturity_date == undefined || this.user.lfs_maturity_date.toLowerCase() == 'null') {
                this.user.lfs_maturity_date = '';
              }
              let extensionDate = this.user.lfs_extension_date.split("-");
              this.user.lfs_extension_date = extensionDate[1] + "/" + extensionDate[2] + "/" + extensionDate[0];
              if (this.user.lfs_extension_date == null || this.user.lfs_extension_date == undefined || this.user.lfs_extension_date.toLowerCase() == 'null') {
                this.user.lfs_extension_date = '';
              }
              if (this.user.lfs_extension_date == "01/01/1990") { this.user.lfs_extension_date = "NA" }
              let terminationdate = this.user.lfs_termination_date.split("-");
              this.user.lfs_termination_date = terminationdate[1] + "/" + terminationdate[2] + "/" + terminationdate[0];
              if (this.user.lfs_termination_date == null || this.user.lfs_termination_date == undefined || this.user.lfs_termination_date.toLowerCase() == 'null') {
                this.user.lfs_termination_date = '';
              }
              if (this.user.lfs_termination_date == "01/01/2100") { this.user.lfs_termination_date = "NA" }
              this.user.veh_acquisition_date = result2.EnrollmentLFSDetail.LFSDetail[0].veh_acquisition_date.substring(0, 10);
              let acqDate = this.user.veh_acquisition_date.split("-");
              this.user.veh_acquisition_date = acqDate[1] + "/" + acqDate[2] + "/" + acqDate[0];
              if (this.user.veh_acquisition_date == null || this.user.veh_acquisition_date == undefined || this.user.veh_acquisition_date.toLowerCase() == 'null') {
                this.user.veh_acquisition_date = '';
              }
              // this.user.lfsTerminationDate = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_termination_date.substring(0, 9);
              this.user.vehicleBrand = result2.EnrollmentLFSDetail.LFSDetail[0].Vehicle_Brand;
              if (this.user.vehicleBrand == null || this.user.vehicleBrand == undefined || this.user.vehicleBrand.toLowerCase() == 'null') {
                this.user.vehicleBrand = '';
              } else {
                this.user.vehicleBrand = result2.EnrollmentLFSDetail.LFSDetail[0].Vehicle_Brand.toUpperCase();
              }

              this.user.vehicleModel = result2.EnrollmentLFSDetail.LFSDetail[0].veh_model;
              if (this.user.vehicleModel == null || this.user.vehicleModel == undefined || this.user.vehicleModel.toLowerCase() == 'null') {
                this.user.vehicleModel = '';
              } else {
                this.user.vehicleModel = result2.EnrollmentLFSDetail.LFSDetail[0].veh_model.toUpperCase();
              }

              this.user.customerType = result2.EnrollmentLFSDetail.LFSDetail[0].customerType;
              if (this.user.customerType == null || this.user.customerType == undefined || this.user.customerType.toLowerCase() == 'null') {
                this.user.customerType = '';
              } else {
                this.user.customerType = result2.EnrollmentLFSDetail.LFSDetail[0].customerType.toUpperCase();
              }

              this.user.vin = result2.EnrollmentLFSDetail.LFSDetail[0].veh_vin;
              if (this.user.vin == null || this.user.vin == undefined || this.user.vin.toLowerCase() == 'null') {
                this.user.vin = '';
              }
            } else {
              this.user.leasedVehicle = 'No';
              this.user.lfsActivationDate = '';
              this.user.veh_acquisition_date = '';
              this.user.lfs_maturity_date = '';
              this.user.lfs_extension_date = '';
              this.user.lfs_termination_date = '';
              this.user.customerType = '';
              //new changes
              this.user.vehicleBrand = '';
              this.user.vehicleModel = '';
              this.user.vin = '';
              this.user.dealerName = '';
              this.user.lfsNo = '';
            };

            if (user.viewEnrollment != undefined) {
              this.user.viewEnrollment = user.viewEnrollment;
              this.user.enrollmentNumber = user.enrollmentNumber;
              this.user.enrollmentStatus = user.enrollmentStatus;
              this.user.envelopeId = user.envelopeId;
              this.user.eSign = user.eSign;
              this.user.sentTo = user.sentTo;
              this.user.email = user.associateEmail;
              this.user.principalEmail = user.principalEmail;
              this.user.GMEmail = user.gmEmail;
              this.user.principalFullName = user.principalName;
              this.user.GMFullName = user.gmName;
            }
            console.log('Before Navigation', this.user);

            this.appService.setEnrollUserData(this.user);
            this.router.navigate(['/enroll']);
          }, err => {
            if (user.viewEnrollment != undefined) {
              this.user.viewEnrollment = user.viewEnrollment;
              this.user.enrollmentNumber = user.enrollmentNumber;
              this.user.enrollmentStatus = user.enrollmentStatus;
              this.user.envelopeId = user.envelopeId;
              this.user.eSign = user.eSign;
              this.user.sentTo = user.sentTo;
              this.user.email = user.associateEmail;
              this.user.principalEmail = user.principalEmail;
              this.user.GMEmail = user.gmEmail;
              this.user.principalFullName = user.principalName;
              this.user.GMFullName = user.gmName;
            }
            console.log('Before Navigation', this.user);

            this.appService.setEnrollUserData(this.user);
            this.router.navigate(['/enroll']);
          });
        });
      }
    });
  }

  async seniorEligibleCheck(row) {
    console.log('in the async fn dashComp', 'row', row);
    return new Promise((resolve, reject) => {
      this.enrollService.seniorEligibleCheck(row).subscribe(async (seniorEligibleResponse: any) => {
        console.log('Senior Eligible', seniorEligibleResponse);
        var certificationLevel = await this.appService.getSeniorEligibileCheck(seniorEligibleResponse.SeniorEligibleCheck.Result)
        console.log('certificationLevel', certificationLevel);
        resolve(certificationLevel);
      })
    });
  }

  closeSpinSearch() {
    this.spinId = "";
    this.spinIdClicked = true;
  }

  focusFunction() {
    this.spinIdClicked = true;
    $('#accordianSection').fadeTo('slow', .4);
    $('#accordianSection').append('<div id="appendDiv" style="position: absolute;top:160px;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
  }

  focusOutFunction() {
    if (this.spinId == "") {
      this.spinIdClicked = false;
      $('#accordianSection').fadeTo('slow', 1);
      $("#appendDiv").remove();
    }
    else if (this.filteredUsers.length > 0) {
    }
    else {
      this.closeSpinSearch();
    }
    $("#appendDiv").remove();
  }

  applyFilter(filterValue: string) {
    if (filterValue != undefined && this.userDataSource != undefined) {
      this.enrollExport = [];
      if (filterValue == '') {
        this.filterPressed = false;

      } else {
        this.filterPressed = true;
      }
      this.selected_count = 0;
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.userDataSource.filter = filterValue;
      this.enrollExport.push(this.userDataSource.filteredData);
      this.userDataSource.paginator = this.paginator.toArray()[0];
      this.userDataSource.sort = this.sort.toArray()[0];
    }
  }

  paymentapplyFilter(filterValue: string) {
    if (filterValue != undefined) {
      this.payExport = [];
      if (filterValue == '') {
        this.pfilterPressed = false;

      } else {
        this.pfilterPressed = true;
      }
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.paymentDataSource.filter = filterValue;
      this.payExport.push(this.paymentDataSource.filteredData);
      this.paymentDataSource.paginator = this.paginator.toArray()[2];
      this.paymentDataSource.sort = this.sort.toArray()[2];
    }
  }

  enrollFilter() {
    this.pendingfilter = false;
    this.userDataSource = new MatTableDataSource(this.ENROLLDATA);
    this.userDataSource.paginator = this.paginator.toArray()[0];
    this.userDataSource.paginator.firstPage();
    this.userDataSource.sort = this.sort.toArray()[0];
    if (this.totalTableData > 0) { this.nodata = false }
  }

  pendingFilter() {
    var radiobtn = (<HTMLInputElement>document.getElementById('ALL'));
    radiobtn.checked = true;
    this.pendingfilter = true;
    this.userDataSource = new MatTableDataSource(this.PENDINGDATA);
    this.pendingTableData = this.PENDINGDATA.length;
    this.userDataSource.paginator = this.paginator.toArray()[0];
    this.userDataSource.paginator.firstPage();
    this.userDataSource.sort = this.sort.toArray()[0];
    if (this.pendingTableData > 0) { this.nodata = false }
  }

  draftFilter() {
    this.pendingfilter = false;
    this.userDataSource = new MatTableDataSource(this.DRAFTDATA);
    this.draftTableData = this.DRAFTDATA.length;
    this.userDataSource.paginator = this.paginator.toArray()[0];
    this.userDataSource.paginator.firstPage();
    this.userDataSource.sort = this.sort.toArray()[0];
    if (this.draftTableData > 0) {
      this.nodata = false
    }
  }

  abandonedFilter() {
    this.userDataSource = new MatTableDataSource(this.ABANDONEDDATA);
    this.abandonedTableData = this.ABANDONEDDATA.length;
    this.userDataSource.paginator = this.paginator.toArray()[0];
    this.userDataSource.paginator.firstPage();
    this.userDataSource.sort = this.sort.toArray()[0];
    if (this.abandonedTableData > 0) {
      this.nodata = false
    }
  }

  deletedFilter() {
    this.userDataSource = new MatTableDataSource(this.DELETEDDATA);
    this.deletedTableData = this.DELETEDDATA.length;
    this.userDataSource.paginator = this.paginator.toArray()[0];
    this.userDataSource.paginator.firstPage();
    this.userDataSource.sort = this.sort.toArray()[0];
    if (this.deletedTableData > 0) { this.nodata = false }

  }


  addTab() {
    this.customFilter = true;
    this.pendingfilter = false;
    this.applyFilter('');
  }

  select(index: number) {
    this.selectedIndex = index;
    if (index == 2) {
      this.enrolledTabName = 'ENROLLED DATE';
    } else {
      this.enrolledTabName = 'CREATED DATE';
    }
  }

  //Selecting the Filter
  getSelected(status) {
    this.filter_status = '';
    this.filter_status = status;
    this.checkedCount = $(":radio:checked").length;
    // console.log(this.checkedCount);
  }

  closeFilterTab() {
    $('input[name=statusFilter]').prop('checked', false);
    this.customFilter = false;
    this.checkedCount = 0;
    this.select(2);
    this.enrollFilter();
  }

  selectedTab(name) {
    this.pendingfilter = false;
    if (name == 'DELETED') {
      this.deletedFilter();
    } else {
      this.abandonedFilter();
    }
  }

  UserPreferenceFilter() {
    $('input[name=statusFilter]').prop('checked', false);
    var i = this.filter_status;
    $("#" + i).attr("disabled", true);
    if (this.filter_status == 'DELETED') {
      this.deletedFilter();
      this.selected_count = this.deletedTableData;
    } else {
      this.abandonedFilter();
      this.selected_count = this.abandonedTableData;
    }
    if (this.filter_array.length < 2) {
      var array = { "name": this.filter_status, "count": this.selected_count }
      this.filter_array.push(array);
      this.newTab = true;
      this.appService.setFilter(array, 'add');
    }
    if (this.filter_array.length == 2) {
      this.newTab = false;
      this.selectedIndex = 1
    }
    if (this.filter_array.length == 1) {
      this.selectedIndex = 0
      this.newTab = true;
    }
    this.customFilter = false;
    this.checkedCount = 0;
  }

  deleteTab(item) {
    var j = item.name;
    $("#" + j).attr("disabled", false);
    this.filter_array = this.filter_array.filter(array => array.name !== item.name);
    this.appService.setFilter(j, 'delete');
    if (this.filter_array.length < 2) {
      this.newTab = true;
    }
    this.select(2);
    this.enrollFilter();
  }

  getPendingFilter(pending) {
    if (pending == 'ALL') {
      this.applyFilter('pending');
      this.pendingfilter = true;
    } else if (pending == 'PENDING WITH ASSOCIATE') {
      this.applyFilter('associate');
      this.pendingfilter = true;
    } else if (pending == 'PENDING WITH PRINCIPAL/GM') {
      this.applyFilter('PENDING WITH PRINCIPAL');
      this.pendingfilter = true;
    } else {
      this.applyFilter('UPLOAD');
      this.pendingfilter = true;
    }
    this.userDataSource.paginator.firstPage();
  }

  // ---------->ASSOCIATE ELIGIBILITY SUMMARY<--------------
  // Filter Function
  eligibleapplyFilter(filterValue: string) {
    this.eligibleExport = [];
    if (filterValue == '') {
      this.efilterPressed = false;
    } else {
      this.efilterPressed = true;
    }
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.eligibleDataSource.filter = filterValue;
    this.eligibleExport.push(this.eligibleDataSource.filteredData);
    this.eligibleDataSource.paginator = this.paginator.toArray()[1];
    this.eligibleDataSource.sort = this.sort.toArray()[1];
    this.hideTooltip = false;
  }

  notEligibleFilter() {
    this.eligible = false;
    this.eligibleDataSource = new MatTableDataSource(this.NONELIGIBLEDATA);
    this.eligibleDataSource.paginator = this.paginator.toArray()[1];
    this.eligibleDataSource.paginator.firstPage();
    this.eligibleDataSource.sort = this.sort.toArray()[1];
    this.hideTooltip = true;
  }

  eligibleFilter() {
    this.eligible = true;
    this.eligibleDataSource = new MatTableDataSource(this.ELIGIBLEDATA);
    this.eligibleDataSource.paginator = this.paginator.toArray()[1];
    this.eligibleDataSource.paginator.firstPage();
    this.eligibleDataSource.sort = this.sort.toArray()[1];
    this.hideTooltip = false;
  }

  // to navigate from table to enroll user.
  getRecord(row: any) {
    console.log('the row clicked: ', row);
    // if ((row.cert_level == 'Master') || (row.cert_level == 'Senior Eligible')) {
    if ((row.Status == 'True' || row.mar_master == "Master")) {
      this.appService.navigationFromDashboard = true;
      this.showSpinner = this.appService.navigationFromDashboard;
      for (let i = 0; i < this.eligibleDataSource.data.length; i++) {
        console.log(this.eligibleDataSource.data[i]);
        if (this.eligibleDataSource.data[i].SPIN_ID == row.SPIN_ID) {
          this.eligibleDataSource.data[i].spinId = row.SPIN_ID;
          console.log(this.eligibleDataSource.data[i].spinId);
          this.appService.certification = row.cert_level;
          //this.userDataSource.data[i].spinId = this.userDataSource.data[i].spin_id;
          //this.eligibleDataSource.data[i].viewEnrollment = false;
          this.selectUser(this.eligibleDataSource.data[i]);
          break;
        }
      }
    }
  }

  viewEnrollmentForm(row: any) {
    if (!this.hideTooltip) {
      this.appService.navigationFromDashboard = true;
      this.showSpinner = this.appService.navigationFromDashboard;
      for (let i = 0; i < this.userDataSource.data.length; i++) {
        if (this.userDataSource.data[i].spinId == row.spinId) {
          // this.userDataSource.data[i].spinId = this.userDataSource.data[i].spin_id;
          console.log('viewEnrollmentForm', this.userDataSource.data[i]);
          this.appService.dashtoenrollEnrollmentForm = this.userDataSource.data[i];
          //this.user.viewEnrollment = true;
          this.userDataSource.data[i].viewEnrollment = true;
          this.selectUser(this.userDataSource.data[i]);
          break;
        }
      }
    }
  }

  notificationViewForm(spinId) {
    this.appService.navigationFromDashboard = true;
    this.showSpinner = this.appService.navigationFromDashboard;
    for (let i = 0; i < this.userDataSource.data.length; i++) {
      if (this.userDataSource.data[i].spinId == spinId) {
        // this.userDataSource.data[i].spinId = this.userDataSource.data[i].spin_id;
        console.log('viewEnrollmentForm', this.userDataSource.data[i]);
        //this.user.viewEnrollment = true;
        this.userDataSource.data[i].viewEnrollment = true;
        this.selectUser(this.userDataSource.data[i]);
        break;
      }
    }
  }

  exportFile() {
    this.NoExcelData = false;
    if (this.sectionName == 'Enroll') {
      let EnrollData = [];
      if (this.filterPressed) {
        if (this.enrollExport[0].length != 0) {
          this.enrollExport.forEach(data => {
            let obj = {
              "Spin_ID": data.spinId,
              "Associate_Name": data.firstName + ' ' + data.lastName,
              "Enrollment_Status": data.enrollmentStatus,
              "Last_Modified_Date": new Date(data.lastModifiedDate),
              "vehicle_vin": data.vehiclevin,
              "leaseExpiry": new Date(data.lfsvalidity),
              "last_paid": data.payduecdandLfspaycd
            }
            EnrollData.push(obj);
          });
          this.excelService.exportAsExcelFile(EnrollData, 'EnrollCriteria');
        } else {
          this.showDownloadErrorModal();
        }
      } else {
        if (this.userDataSource.data.length != 0) {
          this.userDataSource.data.forEach(data => {
            let obj = {
              "Spin_ID": data.spinId,
              "Associate_Name": data.firstName + ' ' + data.lastName,
              "Enrollment_Status": data.enrollmentStatus,
              "Last_Modified_Date": new Date(data.lastModifiedDate),
              "vehicle_vin": data.vehiclevin,
              "Lease_Expiry": data.lfsvalidity == 'NA' ? 'NA' : new Date(data.lfsvalidity),
              "last_paid": data.payduecdandLfspaycd,
              "LEXUS_ESSENTIALS": data.lexEss,
              "TENURE_MONTHS": data.tenure_months,
              "TRAINING_CREDITS +": data.totalCred,
              "TRAINING_CREDITS + ELIGIBILITY": data.eligStat,
              "CERTIFICATION_LEVEL": data.maritzCertFull,
              "REMAINING_PAYMENTS": data.remainingPays,
              "ENROL/AUTO-ENROL DATE": data.enrolOrRenewalDt == 'NA' ? 'NA' : new Date(data.enrolOrRenewalDt),
              "PAYMENT_EXPIRATION": data.payExpiryTime == 'NA' ? 'NA' : new Date(data.payExpiryTime),
            }
            EnrollData.push(obj);
          });
          this.excelService.exportAsExcelFile(EnrollData, 'EnrollCriteria');
        } else {
          this.showDownloadErrorModal();
        }
      }
    } else if (this.sectionName == 'Eligible') {
      let EligibleData = [];
      if (this.efilterPressed) {
        if (this.eligibleExport[0].length != 0) {
          this.eligibleDataSource.data.forEach(data => {
            let obj = {
              "Spin_ID": data.SPIN_ID,
              "Associate_Name": data.FIRST_NAME + ' ' + data.LAST_NAME,
              "Certification_Status": data.cert_level,
              "Eligibility Status": data.Status,
              "Total credits": data["total_credits"],
              "Lexus Essentials": data["lexus_essentials"],
              "Tenure (Months)": data["tenure_months"]

            }
            EligibleData.push(obj);
          });
          this.excelService.exportAsExcelFile(EligibleData, 'EligibleCriteria');
        } else {
          this.showDownloadErrorModal();
        }
      } else {
        if (this.eligibleDataSource.data.length != 0) {
          this.eligibleDataSource.data.forEach(data => {
            let obj = {
              "Spin_ID": data.SPIN_ID,
              "Associate_Name": data.FIRST_NAME + ' ' + data.LAST_NAME,
              "Certification_Status": data.cert_level,
              "Eligibility Status": data.Status,
              "Total credits": data["total_credits"],
              "Lexus Essentials": data["lexus_essentials"],
              "Tenure (Months)": data["tenure_months"]
            }
            EligibleData.push(obj);
          });
          this.excelService.exportAsExcelFile(EligibleData, 'EligibleCriteria');
        } else {
          this.showDownloadErrorModal();
        }
      }
    } else if (this.sectionName == 'Pay') {
      if (this.pfilterPressed) {
        if (this.payExport[0].length != 0) {
          this.excelService.exportAsExcelFile(this.payExport[0], 'PreviousPayment');
        } else {
          this.showDownloadErrorModal();
        }
      } else {
        if (this.paymentDataSource.data.length != 0) {
          this.excelService.exportAsExcelFile(this.paymentDataSource.data, 'PreviousPayment');
        } else {
          this.showDownloadErrorModal();
        }
      }
    } else if (this.sectionName == 'Transfer') {
      let TransferData = [];
      if (this.tfilterPressed) {
        if (this.transferExport[0].length != 0) {
          this.transferDataSource.data.forEach(data => {
            let obj = {
              "Spin_ID": data.spin_id,
              "Associate_Name": data.name,
              "Enrolled_Date": data.enrolled_dt
            }
            TransferData.push(obj);
          });
          this.excelService.exportAsExcelFile(TransferData, 'TransferEnrollment');
        } else {
          this.showDownloadErrorModal();
        }
      } else {
        if (this.transferDataSource.data.length != 0) {
          this.transferDataSource.data.forEach(data => {
            let obj = {
              "Spin_ID": data.spin_id,
              "Associate_Name": data.name,
              "Enrolled_Date": data.enrolled_dt
            }
            TransferData.push(obj);
          });
          this.excelService.exportAsExcelFile(TransferData, 'TransferEnrollment');
        } else {
          this.showDownloadErrorModal();
        }
      }
    }
  }

  addTabServer(filterContent) {
    if (filterContent.length == 2) {
      this.newTab = false;
    }
    this.filter_array = [];
    for (let i = 0; i < filterContent.length; i++) {
      filterContent[i].count = 0;
      this.filter_array.push(filterContent[i]);
    }
  }

  // DEALERSHIP SEGMENT

  transferapplyFilter(filterValue: string) {
    if (filterValue != undefined) {
      this.transferExport = [];
      if (filterValue == '') {
        this.tfilterPressed = false;

      } else {
        this.tfilterPressed = true;
      }
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.transferDataSource.filter = filterValue;
      this.transferExport.push(this.transferDataSource.filteredData);
      this.transferDataSource.paginator = this.transferPaginator;
      this.transferDataSource.sort = this.transferSort;
      this.hideTooltip = false;
    }
  }

  transferFilter() {
    this.transferDataSource = new MatTableDataSource(this.TRANSFERDATA);
    this.transferDataSource.paginator = this.transferPaginator;
    this.transferDataSource.paginator.firstPage();
    this.transferDataSource.sort = this.transferSort;
    this.hideTooltip = false;
  }

  async enrollDealership() {
    this.showSpinner = true;
    await this.getDealerDetails();
    if (this.oldDealer != '' && this.oldDealer != null) {
      this.onLoadData();
      this.transferEnroll = true;
    }
    let role = localStorage.getItem('role');
    if (role == 'Dealer Principal') {
      this.principalFullname = this.appService.loggedInPrincipalFullName;
    }
    if (this.principalFullname == '' || this.principalFullname == null || this.principalFullname == undefined) {
      this.dashboardService.insertDealerEnrollemnt(this.generalManagerFullname).subscribe((response: any) => {
        this.showSpinner = false;
        this.dealershipEnrollmentNo = response.DealerEnrollmentNo;
        console.log('insert', this.generalManagerFullname);
        console.log(this.generalManagerFullname, '\n address ::: ' + this.fullAddress);
        this.makePdf(this.generalManagerFullname, this.fullAddress);
        this.dealershipMessage = 'Enrollment form has been generated for ' + this.dealerName + '. Enrollment ID: ' + response.DealerEnrollmentNo;
        this.dealershipDraft = true;

        this.status = 'DRAFT';
        this.appService.dealershipStatus = this.status;
      }, err => {
        this.showSpinner = false;
        this.dealerInsertErrorMessage = 'Dealer Enrollment form for ' + this.dealerName + ' was not created since neither principal details nor GM details were found. Please contact the Lease My Lexus Support team';
        this.ErrorDealership = true;
      });
    }
    else {
      this.dashboardService.insertDealerEnrollemnt(this.principalFullname).subscribe((response: any) => {
        this.showSpinner = false;
        this.dealershipEnrollmentNo = response.DealerEnrollmentNo;
        console.log('insert', this.principalFullname);
        console.log(this.principalFullname, '\n address ::: ' + this.fullAddress);
        this.makePdf(this.principalFullname, this.fullAddress);
        this.dealershipMessage = 'Enrollment form has been generated for ' + this.dealerName + '. Enrollment ID: ' + response.DealerEnrollmentNo;
        this.dealershipDraft = true;

        this.status = 'DRAFT';
        this.appService.dealershipStatus = this.status;
      }, err => {
        this.showSpinner = false;
        this.dealerInsertErrorMessage = 'Dealer Enrollment form for ' + this.dealerName + ' was not created since principal details were not found. Please contact the Lease My Lexus Support team';
        this.ErrorDealership = true;
      });
    }
  }

  // function to email the enrollment form for Pending with Principal/GM and Resend email.
  emailToPrincipal() {
    this.showSpinner = true;
    var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    this.invalidUserEmail = [];
    this.emailMessage = 'Enrollment form will be emailed for signature to : '
    //Check for GM or Principal
    if (this.emailButton == 'E-SIGN') {
      $('#principalRadio').is(':checked') ? this.selectPrincipal = true : this.selectPrincipal = false;
      $('#gmRadio').is(':checked') ? this.selectGM = true : this.selectGM = false;
    }
    else {
      if (this.dealerFormSentTo == 'Principal') {
        this.selectPrincipal = true;
        this.selectGM = false;
      } else {
        this.selectGM = true;
        this.selectPrincipal = false
      }
    }
    if (this.selectPrincipal) {
      this.selectedOfficialEmail = this.selectedPrincipalEmailId;
      if (!email_regex.test(this.selectedOfficialEmail)) {
        this.invalidUserEmail.push('Please provide proper email format for Principal');
      }
    } else if (this.selectGM) {
      this.selectedOfficialEmail = this.selectedGmEmailId;
      if (!email_regex.test(this.selectedOfficialEmail)) {
        this.invalidUserEmail.push('Please provide proper email format for General Manager');
      }
    }

    // server calls based on send / resend.
    var emailTest = environment.checkDocusignMailId;
    if (emailTest) {
      if (this.selectedOfficialEmail.indexOf('lexuslearn') != -1) {
        emailTest = true;
      }
      else {
        // debugger;
        emailTest = false;
        this.selectedOfficialEmail = 'LexusMasterLease@lexus.com';
      }
    }
    // if (email_regex.test(this.selectedOfficialEmail) && email_regex.test(this.user.email)) {
    if (this.invalidUserEmail.length == 0) {
      if (this.dealershipDraft && !this.dealershipPending) {
        console.log('First time send');
        this.emailSendBtn = true;
        this.dashboardService.getDealerDetails(this.appService.dc).subscribe((dealerResponse: any) => {
          this.dealerDetailsResponse = dealerResponse.DealerDetails.Result[0];
          console.log('dealerResponse', this.dealerDetailsResponse);
          let dlrAddr1 = this.dealerDetailsResponse.dealerAddress1;
          let dlrAddr2 = this.dealerDetailsResponse.dealerAddress2;
          let dlrDetails = '';
          let address = '';
          let city = '';
          dlrDetails = this.dealerDetailsResponse.dealerCode + ' / ' + this.dealerDetailsResponse.dealerName + '\n'
          if (dlrAddr1 != null && dlrAddr2 != null) {
            address = dlrAddr1 + '\n' + dlrAddr2 + '\n';
          } else if (dlrAddr1 != null && dlrAddr2 == null) {
            address = dlrAddr1 + '\n';
          } else if (dlrAddr1 == null && dlrAddr2 != null) {
            address = dlrAddr2 + '\n';
          }
          city = this.dealerDetailsResponse.dealerCity + ', ' + this.dealerDetailsResponse.dealerState + ' ' + this.dealerDetailsResponse.dealerZip;
          this.fullAddress = dlrDetails + address + city;
          if (this.selectPrincipal) {
            // console.log('Check 1 :::: ', this.principalName, this.selectedPrincipalEmailId );
            // console.log('Check 2 :::: ', this.principalFullname, this.principalEmailId );
            this.principalFullname = this.principalName;
            this.dashboardService.docusignSendMail(this.fullAddress, this.selectedPrincipalEmailId, this.principalName, this.principalSpinid, this.associateFullname, this.associateEmailId).subscribe(response => {
              this.showSpinner = false;
              console.log('SendToDocusignDealerMail ::: ', response);
              this.dealershipMessage = 'Enrollment form has been sent to ' + this.principalFullname + ' (Principal) for signature. Enrollment ID: '
                + this.dealershipEnrollmentNo;
              this.dealershipEnvelopeId = response.envelopeId;
              console.log(this.principalFullname, '\n address ::: ' + this.fullAddress);
              this.makePdf(this.principalFullname, this.fullAddress);
              this.showSpinner = true;
              this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'PENDING WITH PRINCIPAL', this.dealershipEnvelopeId, this.principalFullname, 'Principal')
                .subscribe((result: any) => {
                  this.showSpinner = false;
                  this.pdfLoading = true;
                  // this.cdref.detectChanges();
                  this.emailButton = 'E-SIGN (RESEND)';
                  this.emailMessage = 'Enrollment form has been sent for signatures to : ';
                  this.status = "PENDING WITH PRINCIPAL";
                  this.message = 'Enrollment form is currently with ' + this.principalFullname + ' (Principal) for signature. '
                  this.emailSendBtn = false;
                  this.pendingMailResend = true;
                });
              this.dealershipPending = true;
              this.dealershipDraft = false;
              this.disableMailButton = true;
              if (this.oldDealer != '' && this.oldDealer != null) { this.transferEnroll = true; }
              this.status = 'PENDING WITH PRINCIPAL';
              this.appService.dealershipStatus = this.status;
              this.sendFormToPrincipal = true;
            }, error => {
              this.showSpinner = false;
              console.log('Error while trying to send mail from docusign');
              this.emailSendBtn = false;
              this.invalidUserEmail.push('Some Error Occured');
            });
          } else if (this.selectGM) {
            // console.log('Check 1 :::: ', this.gmName, this.selectedGmEmailId );
            // console.log('Check 2 :::: ', this.generalManagerFullname, this.generalManagerEmailId );
            this.generalManagerFullname = this.gmName;
            this.dashboardService.docusignSendMail(this.fullAddress, this.selectedGmEmailId, this.gmName, this.generalManagerSpinid, this.associateFullname, this.associateEmailId).subscribe(response => {
              this.showSpinner = false;
              console.log('SendToDocusignDealerMail ::: ', response);
              this.dealershipMessage = 'Enrollment form has been sent to ' + this.generalManagerFullname + ' (General Manager) for signature. Enrollment ID: '
                + this.dealershipEnrollmentNo;
              this.dealershipEnvelopeId = response.envelopeId;
              console.log(this.generalManagerFullname, '\n address ::: ' + this.fullAddress);
              this.makePdf(this.generalManagerFullname, this.fullAddress);
              this.showSpinner = true;
              this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'PENDING WITH GM', this.dealershipEnvelopeId, this.generalManagerFullname, 'GM')
                .subscribe((result: any) => {
                  this.showSpinner = false;
                  this.pdfLoading = true;
                  this.emailButton = 'E-SIGN (RESEND)';
                  this.emailMessage = 'Enrollment form has been sent for signatures to : ';
                  this.status = "PENDING WITH GM";
                  this.message = 'Enrollment form is currently with ' + this.generalManagerFullname + ' (General Manager) for signature. '
                  this.emailSendBtn = false;
                  this.pendingMailResend = true;
                });
              this.dealershipDraft = false;
              this.dealershipPending = true;
              this.disableMailButton = true;
              if (this.oldDealer != '' && this.oldDealer != null) {
                //this.onLoadData();
                this.transferEnroll = true;
              }
              this.status = 'PENDING WITH GM';
              this.appService.dealershipStatus = this.status;
              this.sendFormToPrincipal = true;
              this.ngOnInit();
            }, error => {
              this.showSpinner = false;
              console.log('Error while trying to send mail from docusign');
              this.emailSendBtn = false;
              this.invalidUserEmail.push('Some Error Occured');
            });
          }
        }, err => {
          console.log('Error while trying to fetch the Dealer details');
        });
      } else {
        console.log('Resend');
        if (this.status == 'PENDING WITH PRINCIPAL' || this.status == 'PENDING WITH GM') {
          this.emailSendBtn = true;
          if (this.selectPrincipal) {
            // console.log('Check 1 :::: ', this.principalName, this.selectedPrincipalEmailId);
            // console.log('Check 2 :::: ', this.principalFullname, this.principalEmailId);
            this.principalFullname = this.principalName;
            this.dashboardService.docusignSendMail(this.fullAddress, this.selectedPrincipalEmailId, this.principalFullname, this.principalSpinid, this.associateFullname, this.associateEmailId).subscribe(response => {
              console.log('SendToDocusignDealerMail ::: ', response);
              this.showSpinner = false;
              this.dealershipMessage = 'Enrollment form has been resent to ' + this.principalFullname + ' (Principal) for signature. Enrollment ID: '
                + this.dealershipEnrollmentNo;
              this.dealershipEnvelopeId = response.envelopeId;
              console.log(this.principalFullname, '\n address ::: ' + this.fullAddress);
              this.makePdf(this.principalFullname, this.fullAddress);
              this.showSpinner = true;
              this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'PENDING WITH PRINCIPAL', this.dealershipEnvelopeId, this.principalFullname, 'Principal')
                .subscribe((result: any) => {
                  this.showSpinner = false;
                  this.pdfLoading = true;
                  this.disableResendButton = true;
                  this.emailButton = 'E-SIGN (RESEND)';
                  this.emailMessage = 'Enrollment form has been resent for signatures to : ';
                  this.status = "PENDING WITH PRINCIPAL";
                  this.message = 'Enrollment form is currently with ' + this.principalFullname + ' (Principal) for signature. '
                  this.emailSendBtn = false;
                  this.pendingMailResend = true;
                });
              this.dealershipPending = true;
              this.dealershipDraft = false;
              this.disableMailButton = true;
              if (this.oldDealer != '' && this.oldDealer != null) {
                //this.onLoadData();
                this.transferEnroll = true;
              }
              this.status = 'PENDING WITH PRINCIPAL';
              this.appService.dealershipStatus = this.status;
              this.sendFormToPrincipal = true;
            }, error => {
              this.showSpinner = false;
              console.log('Error while trying to resend mail from docusign');
              this.emailSendBtn = false;
              this.invalidUserEmail.push('Some Error Occured');
            });
          } else if (this.selectGM) {
            // console.log('Check 1 :::: ', this.gmName, this.selectedGmEmailId);
            // console.log('Check 2 :::: ', this.generalManagerFullname, this.generalManagerEmailId);
            this.generalManagerFullname = this.gmName;
            this.dashboardService.docusignSendMail(this.fullAddress, this.selectedGmEmailId, this.generalManagerFullname, this.generalManagerSpinid, this.associateFullname, this.associateEmailId).subscribe(response => {
              console.log('SendToDocusignDealerMail ::: ', response);
              this.showSpinner = false;
              this.dealershipMessage = 'Enrollment form has been resent to ' + this.generalManagerFullname + ' (General Manager) for signature. Enrollment ID: '
                + this.dealershipEnrollmentNo;
              this.dealershipEnvelopeId = response.envelopeId;
              console.log(this.generalManagerFullname, '\n address ::: ' + this.fullAddress);
              this.makePdf(this.generalManagerFullname, this.fullAddress);
              this.showSpinner = true;
              this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'PENDING WITH GM', this.dealershipEnvelopeId, this.generalManagerFullname, 'GM')
                .subscribe((result: any) => {
                  this.showSpinner = false;
                  this.pdfLoading = true;
                  this.disableResendButton = true;
                  this.emailButton = 'E-SIGN (RESEND)';
                  this.emailMessage = 'Enrollment form has been resent for signatures to : ';
                  this.status = "PENDING WITH GM";
                  this.message = 'Enrollment form is currently with ' + this.generalManagerFullname + ' (General Manager) for signature. '
                  this.emailSendBtn = false;
                  this.pendingMailResend = true;
                });
              this.dealershipDraft = false;
              this.dealershipPending = true;
              this.disableMailButton = true;
              if (this.oldDealer != '' && this.oldDealer != null) {
                //this.onLoadData();
                this.transferEnroll = true;
              }
              this.status = 'PENDING WITH GM';
              this.appService.dealershipStatus = this.status;
              this.sendFormToPrincipal = true;
            }, error => {
              this.showSpinner = false;
              console.log('Error while trying to resend mail from docusign');
              this.emailSendBtn = false;
              this.invalidUserEmail.push('Some Error Occured');
            });
          }
        }
      }
    }
  }

  // function to select the radio button (Principal or GM) by default based on earlier selection
  emailForm() {
    if (this.dealershipDraft && !this.dealershipPending) {
      if (this.principalDetailResponse.length != 0) {
        (<HTMLInputElement>document.getElementById("principalRadio")).checked = true;
        $('#PD').addClass('fontBold');
        $('#GM').removeClass('fontBold');
        $('#GMEmailSelect').prop('disabled', 'disabled');
        $('#GMEmail').attr('disabled', true);
        $('#principalEmailSelect').attr('disabled', false);
        $('#principalEmail').attr('disabled', true);
        this.selectPrincipal = true;
        this.selectGM = false;
        this.dealerFormSentTo = 'Principal';
      } else {
        (<HTMLInputElement>document.getElementById("gmRadio")).checked = true;
        $('#GM').addClass('fontBold');
        $('#PD').removeClass('fontBold');
        $('#principalEmailSelect').prop('disabled', 'disabled');
        $('#principalEmail').attr('disabled', true);
        $('#GMEmailSelect').attr('disabled', false);
        $('#GMEmail').attr('disabled', true);
        this.selectPrincipal = false;
        this.selectGM = true;
        this.dealerFormSentTo = 'GM';
      }
    } else {
      if (this.dealerFormSentTo.toLowerCase() == 'principal') {
        this.selectPrincipal = true;
        this.selectGM = false;
        (<HTMLInputElement>document.getElementById("principalRadio")).checked = true;
        this.principalName = this.principalOrGm;
        this.principalDetailResponse.forEach(elt => {
          if (elt.FULL_NAME == this.principalOrGm) {
            this.selectedPrincipalEmailId = elt.USER_EMAIL;
          }
        });
        // $("#gmRadio").attr("disabled", true);
        $('#GMEmailSelect').prop('disabled', 'disabled');
        $('#GMEmail').attr('disabled', true);
        // $('#principalEmailSelect').prop('disabled', 'disabled');
      } else {
        this.selectGM = true;
        this.selectPrincipal = false;
        (<HTMLInputElement>document.getElementById("gmRadio")).checked = true;
        this.gmName = this.principalOrGm;
        this.gmDetailResponse.forEach(elt => {
          if (elt.FULL_NAME == this.principalOrGm) {
            this.selectedGmEmailId = elt.USER_EMAIL;
          }
        });
        // $("#principalRadio").attr('disabled', true);
        $('#principalEmailSelect').prop('disabled', 'disabled');
        $('#principalEmail').attr('disabled', true);
        // $('#GMEmailSelect').prop('disabled', 'disabled');
      }
    }
  }

  // function to update when principal radio button is clicked
  principalSelectChange() {
    if ($('#principalRadio').is(':checked')) {
      this.selectGM = false;
      this.selectPrincipal = true;
      this.principalName = '';
      this.selectedPrincipalEmailId = '';
      this.principalSpinid = '';
      $('#PD').addClass('fontBold');
      $('#GM').removeClass('fontBold');
      $('#GMEmailSelect').prop('disabled', 'disabled');
      $('#GMEmail').attr('disabled', true);
      $('#principalEmailSelect').attr('disabled', false);
      $('#principalEmail').attr('disabled', true);
      this.emailSendBtn = false;
      this.dealerFormSentTo = 'Principal';
    }
  }

  // function to update when principal radio button is clicked
  GMSelectValidation() {
    if ($('#gmRadio').is(':checked')) {
      this.selectGM = true;
      this.selectPrincipal = false;
      this.gmName = '';
      this.selectedGmEmailId = '';
      this.generalManagerSpinid = '';
      $('#PD').removeClass('fontBold');
      $('#GM').addClass('fontBold');
      $('#GMEmailSelect').attr('disabled', false);
      $('#GMEmail').attr('disabled', true);
      $('#principalEmailSelect').prop('disabled', 'disabled');
      $('#principalEmail').attr('disabled', true);
      this.emailSendBtn = false;
      this.dealerFormSentTo = 'GM';
    }
  }

  changePDDetails(pd) {
    this.invalidUserEmail = [];
    if (pd == '') {
      this.principalEmailId = '';
      this.selectedPrincipalEmailId = '';
      this.principalSpinid = '';
      this.principalFullname = '';
    } else {
      this.principalDetailResponse.forEach(elt => {
        if (elt.FULL_NAME == pd) {
          this.principalEmailId = elt.USER_EMAIL;
          this.selectedPrincipalEmailId = elt.USER_EMAIL;
          this.principalSpinid = elt.SPIN_ID;
          this.principalFullname = pd;
        }
      });
    }
    console.log('PD Email :: ', this.principalEmailId);
    console.log('PD List :: ', this.principalDetailResponse);
  }

  changeGMDetails(gm) {
    this.invalidUserEmail = [];
    if (gm == '') {
      this.generalManagerEmailId = '';
      this.selectedGmEmailId = '';
      this.generalManagerSpinid = '';
      this.generalManagerFullname = '';
    } else {
      this.gmDetailResponse.forEach(elt => {
        if (elt.FULL_NAME == gm) {
          this.generalManagerEmailId = elt.USER_EMAIL;
          this.selectedGmEmailId = elt.USER_EMAIL;
          this.generalManagerSpinid = elt.SPIN_ID;
          this.generalManagerFullname = gm;
        }
      });
    }
    console.log('GM Email :: ', this.generalManagerEmailId);
    console.log('GM LIST :: ', this.gmDetailResponse);
  }

  capitalize(s) {
    if (s.trim() != '' && typeof s === 'string') {
      return s
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
    }
  }

  async getDealerDetails() {
    await this.dashboardService.getDealerDetails(this.appService.dc).toPromise().then(async (dealerResponse: any) => {
      this.dealerDetailsResponse = dealerResponse.DealerDetails.Result[0];
      console.log('dealerResponse', this.dealerDetailsResponse);
      this.dealerName = this.dealerDetailsResponse.dealerName;
      // this.capitalize(this.dealerDetailsResponse.dealerName); 
      let dlrAddr1 = this.dealerDetailsResponse.dealerAddress1;
      let dlrAddr2 = this.dealerDetailsResponse.dealerAddress2;
      this.oldDealer = this.dealerDetailsResponse.oldDealer;
      // console.log(this.oldDealer);
      let dlrDetails = '';
      let address = '';
      let city = '';
      dlrDetails = this.dealerDetailsResponse.dealerCode + ' / ' + this.dealerDetailsResponse.dealerName + '\n'
      if (dlrAddr1 != null && dlrAddr2 != null) {
        address = dlrAddr1 + '\n' + dlrAddr2 + '\n';
      } else if (dlrAddr1 != null && dlrAddr2 == null) {
        address = dlrAddr1 + '\n';
      } else if (dlrAddr1 == null && dlrAddr2 != null) {
        address = dlrAddr2 + '\n';
      }
      city = this.dealerDetailsResponse.dealerCity + ', ' + this.dealerDetailsResponse.dealerState + ' ' + this.dealerDetailsResponse.dealerZip;
      this.fullAddress = dlrDetails + address + city;
      // console.log('Address ::: ', this.fullAddress);
    }, err => {
      console.log('error in getting the dealer details');
    });
  }

  EnrolledStatus() {
    this.appService.dealershipEnrollment = false;
    this.dealershipEnrollment = false;
    this.dealershipDraft = false;
    this.dealershipUpload = false;
    this.dealershipPending = false;
    this.dealershipMessage = 'Dealership has been successfully enrolled and has been sent to Lexus College.';
    this.dealershipSuccessMessage = 'Dealership has been successfully enrolled and has been sent to Lexus College.';
    this.status = 'ENROLLED';
    this.appService.dealershipStatus = this.status;
  }

  closeModal() {
    $("#emailToPrincipalModal").removeClass('display_block');
    $("#emailToPrincipalModal").addClass('hide_block');
    $("#emailToPrincipalModal").show("slow");
  }

  // function to hide the 'Send to Associate, Principal / GM' modal popup after successfully sending mail
  okButton() {
    this.emailMessage = 'Enrollment form will be emailed for signature to : '
    $("#emailToPrincipalModal").removeClass('display_block');
    $("#emailToPrincipalModal").addClass('hide_block');
    $("#emailToPrincipalModal").show("slow");
    // $('#emailToPrincipalModal').hide();
    // $('body').removeClass('modal-open');
    // $('.modal-backdrop').remove();
  }
  showEmailModal() {
    $("#emailToPrincipalModal").removeClass('hide_block');
    $("#emailToPrincipalModal").addClass('display_block');
    $("#emailToPrincipalModal").show("slow");
    this.emailSendBtn = false;
  }

  closeModalIframe() {
    this.enrollmentCountDown = 30;
    $("#iframeModal").removeClass('display_block');
    $("#iframeModal").addClass('hide_block');
    $("#iframeModal").show("slow");
    this.closedIframe = true;
    setTimeout(function () {
      this.closedIframe = false;
      this.onLoadData();
      this.appComponent.loginFunction()
    }.bind(this), 30000);
    this.enrollmentMsg = "Dealer enrollment is in-progress. Please wait for " + this.enrollmentCountDown + " seconds."
    $("#enrollmentMessage").attr('data-text', this.enrollmentMsg + '');
    var countDown = setInterval(
      function () {
        this.enrollmentCountDown -= 1;
        this.enrollmentMsg = "Dealer enrollment is in-progress. Please wait for " + this.enrollmentCountDown + " seconds."
        $("#enrollmentMessage").attr('data-text', this.enrollmentMsg + '');
        if (this.enrollmentCountDown == 0) {
          clearInterval(countDown);
        }
      }.bind(this),
      1000
    );
  }

  openIframe() {
    $("#iframeModal").removeClass('hide_block');
    $("#iframeModal").addClass('display_block');
    $("#iframeModal").show("slow");
    console.log("iframe :: ", this.iframeOpenedOnce);
    if (!this.iframeOpenedOnce) {
      this.signIframeLoading = true;
      this.dashboardService.getDealerDetails(this.appService.dc).subscribe((dealerResponse: any) => {
        this.dealerDetailsResponse = dealerResponse.DealerDetails.Result[0];
        console.log('dealerResponse', this.dealerDetailsResponse);
        let dlrAddr1 = this.dealerDetailsResponse.dealerAddress1;
        let dlrAddr2 = this.dealerDetailsResponse.dealerAddress2;
        let dlrDetails = '';
        let address = '';
        let city = '';
        dlrDetails = this.dealerDetailsResponse.dealerCode + ' / ' + this.dealerDetailsResponse.dealerName + '\n'
        if (dlrAddr1 != null && dlrAddr2 != null) {
          address = dlrAddr1 + '\n' + dlrAddr2 + '\n';
        } else if (dlrAddr1 != null && dlrAddr2 == null) {
          address = dlrAddr1 + '\n';
        } else if (dlrAddr1 == null && dlrAddr2 != null) {
          address = dlrAddr2 + '\n';
        }
        city = this.dealerDetailsResponse.dealerCity + ', ' + this.dealerDetailsResponse.dealerState + ' ' + this.dealerDetailsResponse.dealerZip;
        this.fullAddress = dlrDetails + address + city;
        // console.log('Address ::: ', this.fullAddress);
        this.principalEmailId = this.appService.loggedInPrincipalEmail;
        this.principalFullname = this.appService.loggedInPrincipalFullName;
        this.principalSpinid = this.appService.loggedInPrincipalspinId;
        this.dashboardService.principalSignMail(this.fullAddress, this.principalEmailId, this.principalFullname, this.principalSpinid, this.associateFullname, this.associateEmailId)
          .subscribe((response: any) => {
            this.dealershipEnvelopeId = response.EmbeddedSigning.Result[0].envelopeId;
            console.log(response.EmbeddedSigning.Result[0]);
            this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'PENDING WITH PRINCIPAL', this.dealershipEnvelopeId, this.principalFullname, 'Principal')
              .subscribe((result: any) => {
              });
            this.signIframeLoading = false;
            this.iframeOpenedOnce = true;
            (<HTMLInputElement>document.getElementById('principalSign')).src = response.EmbeddedSigning.Result[0].url;
            // console.log(document.getElementById("principalSign").contentWindow.location.href);
          });
      });
    }
  }

  getPaymentHistoryDetail() {
    this.paymentTableLoading = true;
    this.PAYMAINDATA = [];
    this.paymentHistoryResponse = '';
    this.PAYMENTDATA = [];
    this.dashboardService.getPayHistoryData(this.appService.dc).subscribe((response: any) => {
      this.paymentHistoryResponse = response.PaymentSummary.Result;
      [].push.apply(this.PAYMENTDATA, this.paymentHistoryResponse);
      if (this.PAYMENTDATA) {
        this.paymentDataSource = new MatTableDataSource(this.PAYMENTDATA);
        this.paymentDataSource.paginator = this.paginator.toArray()[2];
        this.paymentDataSource.sort = this.sort.toArray()[2];
        this.paymentTotalData = this.PAYMENTDATA.length;
      } else {
        this.paymentNoData = true;
        this.paymentTotalData = 0;
      };
      this.paymentTableLoading = false;
    }, error => {
      this.paymentNoData = true;
      this.paymentTotalData = 0;
      this.paymentTableLoading = false;
      console.log('Error', error);
      console.log('Error While getting data for the payment Summary Table');
    });
  };

  scrollUp() {
    $('html,body').animate({ scrollTop: 0 }, "smooth");
  }
  scrollDown() {
    $('html,body').animate({ scrollTop: document.body.scrollHeight }, "smooth");
  }
  scroll50() {
    $('html,body').animate({ scrollTop: document.body.scrollHeight / 3 }, "smooth");
  }

  showDownloadErrorModal() {
    $("#errorExcelModal").removeClass('hide_block');
    $("#errorExcelModal").addClass('display_block');
    $("#errorExcelModal").show("slow");
  }

  closeDownloadErrorModal() {
    $("#errorExcelModal").removeClass('display_block');
    $("#errorExcelModal").addClass('hide_block');
    $("#errorExcelModal").show("slow");
  }

  // Manual Signing

  // function to print the enrollemnt form (for enrolled status)
  print() {
    this.sentToAssociate = false;
    if (this.checkChrome) {
      (<HTMLIFrameElement>document.getElementById("expandImage")).contentWindow.print();
    } else {
      let type = this.fileBlob.type.split('/');
      var name = "" + this.dealershipEnrollmentNo + '.' + type[1];
      window.navigator.msSaveOrOpenBlob(this.fileBlob, name);
    }
  }

  async downloadMEFile() {
    console.log(this.enrollmentform);
    this.pdfLoading = true;
    let containerurl = environment.azureStorage.split('/');
    let container = containerurl[containerurl.length - 2];
    await this.SasToken(container);
    await this.enrollService.azureFile(this.enrollmentform + '?' + this.sasTokenResponse).toPromise().then(async (response: any) => {
      console.log(response.content._body.type);
      var file = new Blob([response.content._body], { type: response.content._body.type });
      var fileURL = URL.createObjectURL(file);
      this.pdfLoading = false;
      // this.cdref.detectChanges();
      this.fileUrl = fileURL;
      this.fileBlob = file;
      if (this.checkChrome) {
        var downloadUrl = URL.createObjectURL(this.fileBlob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "" + this.dealershipEnrollmentNo + response.content._body.type;
        document.body.appendChild(a);
        a.click();
      } else {
        let type = this.fileBlob.type.split('/');
        var name = "" + this.dealershipEnrollmentNo + type[1];
        window.navigator.msSaveOrOpenBlob(this.fileBlob, name);
      }
    }, err => {
      this.enrolformNotFound = true;
      this.pdfLoading = false;
    });
  }

  // function to print the enrollemnt form (for pending upload status ) or update the status to pending upload (for Draft status)
  manualSign() {
    this.showSpinner = true;
    if (this.appService.dealershipStatus == 'DRAFT') {
      const myDate = new Date();
      this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'PENDING UPLOAD', 'NULL', 'NULL', 'NULL').subscribe((response: any) => {
        var rowCount = response.RowUpdated.rowCount;
        this.showSpinner = false;
        this.dealershipDraft = false;
        this.dealershipPending = true;
        this.dealershipUpload = true;
        this.disableMailButton = true;
        if (rowCount) {
          if (this.checkChrome) {
            (<HTMLIFrameElement>document.getElementById("expandImage")).contentWindow.print();
          } else {
            window.navigator.msSaveOrOpenBlob(this.draftdealerData, "EnrollmentForm.pdf");
          }
          this.sentToAssociate = true;
          this.dealershipMessage = 'Enrollment form has been printed for manual signature process. Enrollment ID: '
            + this.dealershipEnrollmentNo;
          this.status = 'PENDING UPLOAD';
          this.appService.dealershipStatus = 'PENDING UPLOAD';
        } else {
          this.showFormImage = false;
          this.message = 'Sorry Please Try Again.';
        }
      }, err => {
        console.log('Error while updating status as Pending upload');
        this.showSpinner = false;
      });
    } else {
      this.showSpinner = false;
      this.sentToAssociate = true;
      if (this.checkChrome) {
        (<HTMLIFrameElement>document.getElementById("expandImage")).contentWindow.print();
      }
      else {
        window.navigator.msSaveOrOpenBlob(this.draftdealerData, "EnrollmentForm.pdf");
      }
    }
  }

  // function to check if the checkbox(I have signed the...) are checked before manual upload
  principal() {
    var principal = (<HTMLInputElement>document.getElementById("principalCheckBox")).checked
    if (principal) {
      this.browseDisable = false;
    }
    else {
      this.browseDisable = true;
    }
  }

  // function to handle the selected file and make the necessary validations on the selected files
  handleFileInput(files: FileList) {
    let maxFileSize = 3 * 1024 * 1024;
    if (files.item(0).type == 'application/pdf') {
      if (files.item(0).size <= maxFileSize) {
        this.pdfError = false;
        console.log('BrowsedFile', files);
        this.fileToUpload = files.item(0);
        this.checkbox = true;
        this.browseDisable = true;
        $('#principalCheckBox').prop('checked', false);
        console.log('Browsed', this.fileToUpload);
        this.fileName = this.fileToUpload.name;
      } else {
        this.pdfError = true;
        this.errorMsg = "Please upload a PDF document of size less than 3MB";
      }
    } else {
      this.pdfError = true;
      this.errorMsg = "Please upload a valid PDF document";
      this.fileToUpload = files.item(0);
    }
  }

  // function to allow dragging files over the 'div' for manual uploar.
  allowDrop(ev) {
    ev.preventDefault();
  }

  // function to handle the dropped file in the 'drop' region.
  dropHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault()

    if (ev.dataTransfer.items && ev.dataTransfer.items.length == 1) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {

          var files = ev.dataTransfer.items[i].getAsFile();
          var ext = files.name.split('.').pop();
          if (ext == "pdf") {
            this.pdfError = false;
            console.log(files);
            console.log('... files[' + i + '].name = ' + files.name);
            this.fileToUpload = files;
            this.checkbox = true;
            $('#principalCheckBox').prop('checked', false);
            this.browseDisable = true;
            console.log('Dropped', this.fileToUpload);
            this.fileName = this.fileToUpload.name;

            this.pdfHref = '';
            let temppath = URL.createObjectURL(this.fileToUpload);
            this.pdfHref = temppath;
            $("#openPDF").attr("href", this.pdfHref);
          } else {
            this.pdfError = true;
            this.errorMsg = "Please upload a valid PDF document";
            setTimeout(function () {
              this.pdfError = false;
            }.bind(this), 15000);
          }
        } else {
          this.pdfError = true;
          this.errorMsg = "Please upload a PDF document of size less than 3MB";
          setTimeout(function () {
            this.pdfError = false;
          }.bind(this), 15000);
        }
      }
    }
    else {
      this.fileToUpload = null;
      this.checkbox = false;
      this.browseDisable = false;
      this.fileName = undefined;
    }
  }

  //function to move the selected file to Azure blob storage
  async uploadFile() {
    console.log('insideUpload');
    console.log('environment.baseUrl', environment.baseUrl);
    let Config: UploadParams;
    if (environment.baseUrl.indexOf('lmlqafunction') != -1) {
      await this.SasToken('dealer');
      Config = {
        sas: '?' + this.sasTokenResponse,
        storageAccount: 'lmlqastorage',
        containerName: 'dealer'
      };
    }
    else if (environment.baseUrl.indexOf('lmlprodfunction') != -1) {
      await this.SasToken('dealer');
      Config = {
        sas: '?' + this.sasTokenResponse,
        storageAccount: 'lmlprodstorage',
        containerName: 'dealer'
      };
    }
    else {
      await this.SasToken('dealer');
      Config = {
        sas: '?' + this.sasTokenResponse,
        storageAccount: 'lmlqastorage',
        containerName: 'dealer'
      };
    }

    if (this.fileToUpload !== null) {
      console.log('File to upload is not null', this.fileToUpload);
      var name = this.fileToUpload.type.split('/');

      // let blob = BlobService;
      var baseUrl = this.blob.generateBlobUrl(Config, this.dealershipEnrollmentNo + '.' + name[1]);
      console.log('baseUrl', baseUrl, ' ::: \n  sas   ' + Config.sas);
      this.config = {
        baseUrl: baseUrl,
        sasToken: Config.sas,
        blockSize: 1024 * 64, // OPTIONAL, default value is 1024 * 32
        file: this.fileToUpload,
        complete: () => {
          console.log('Transfer completed !');
          var myDate = new Date();
          this.dashboardService.statusUpdate(this.dealershipEnrollmentNo, 'ENROLLED', 'NULL', 'NULL', 'NULL').subscribe((response: any) => {
            var rowCount = response.RowUpdated.rowCount;
            if (rowCount) {
              this.status = "ENROLLED";
              this.fileName = this.dealershipEnrollmentNo;
              this.successMessage = true;
              this.dealershipDraft = false;
              this.dealershipPending = true;
              this.dealershipUpload = false;
              this.dealershipMessage = "Congratulations! " + this.appService.dealerName + " (" + this.appService.dc + ") has been successfully enrolled";
              $('#requestModal').hide();
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              this.uploadAccordian = false;
              this.sentToAssociate = false;
              this.pdfLoading = true;
              // this.cdref.detectChanges();
              console.log('this.dealershipEnrollmentNo', this.dealershipEnrollmentNo);
              if (this.checkChrome) {
                this.enrollService.azureFile(this.dealershipEnrollmentNo + '.pdf?' + this.sasTokenResponse).subscribe((result: any) => {
                  console.log('Status updated after upload', result);
                  var file = new Blob([result.content._body], { type: 'application/pdf' });
                  var fileURL = URL.createObjectURL(file);
                  this.fileUrl = fileURL;
                  this.fileBlob = file;
                  this.disablePrint = false;
                  this.pdfLoading = false;
                  // this.cdref.detectChanges();
                  this.expandFormNotFound = false;
                }, err => {
                  console.log('error while trying to download the file from url after upload');
                });
              } else {
                setTimeout(() => {
                  (<HTMLIFrameElement>document.getElementById('expandImage')).src = environment.azureStorage + this.dealershipEnrollmentNo + '.pdf?' + this.sasTokenResponse;
                }, 2500);
              }
              setTimeout(() => {
                this.onLoadData();
              }, 500);
            } else {
              // this.showFormImage = false;
              // this.message = 'Sorry Please Try Again.';
            }
          }, err => {
            console.log('Error while trying to update the status after upload');
          });


        },
        error: (err) => {
          console.log('Error in uploading file :::::', err);
          this.errorUploadForm = true;
        },
        progress: (percent) => {
          this.progressValue = percent;
        }
      };
      this.blob.upload(this.config);
    }
  }

  async SasToken(container) {
    this.sasTokenResponse = '';
    await this.dashboardService.getSasToken(container).toPromise().then(async (response: any) => {
      this.sasTokenResponse = response.SasToken.Result;
      console.log('Sas Token ::' + this.sasTokenResponse);
    }, err => {
      console.log('Error while trying to get Sas Token ::' + err);
    });
  }

  // function to remove the manually uploaded file
  clearFile() {
    this.fileToUpload = null;
    this.fileName = undefined;
  }


  // function to view the file that was manually uploaded now.
  viewUploadedFile(e) {
    this.pdfHref = '';
    var input = e.target
    var tmppath = URL.createObjectURL(input.files[0]);
    this.pdfHref = tmppath;
    $("#openPDF").attr("href", this.pdfHref);
  }

  // function to open the file in a new tab that was manually uploaded now.
  openPdf() {
    var blob = new Blob([this.fileToUpload], { type: '.pdf' });
    var url = window.URL.createObjectURL(blob);
    $('#openPDF').attr('href', url);
    navigator.msSaveOrOpenBlob(blob, 'enrollmentForm.pdf');
  }

  // pdf maker

  makePdf(name, address): any {
    pdfmake.vfs = pdfFonts.pdfMake.vfs;
    var docDefinition;
    console.log(new Date());
    this.makeBase64('1').then(bgImage1 => {

      this.makeBase64('2').then(bgImage2 => {

        this.makeBase64('3').then(bgImage3 => {

          this.makeBase64('4').then(bgImage4 => {

            this.makeBase64('5').then(bgImage5 => {

              this.makeBase64('6').then(bgImage6 => {

                this.makeBase64('7').then(bgImage7 => {

                  this.makeBase64('8').then(bgImage8 => {

                    this.makeBase64('9').then(bgImage9 => {

                      this.makeBase64('10').then(bgImage10 => {


                        docDefinition = {
                          background: function (currentPage, pageSize) {
                            if (currentPage == 1) {
                              return {
                                image: bgImage1,
                                width: pageSize.width,
                                height: pageSize.height
                              }
                            }
                            else {
                              return ''
                            }
                          },
                          pageMargins: [0, 0, 0, 0],
                          content: [
                            {
                              text: address,
                              bold: true, fontSize: 10,
                              margin: [-10, 130, 0, 0], alignment: 'center'
                            },

                            {

                              text: name, bold: true, fontSize: 12,
                              margin: [100, 490, 0, 0], pageBreak: 'after'
                            },
                            {
                              image: bgImage2,
                              width: 595.28,
                              height: 841.89,
                              pageBreak: 'after',

                            },
                            {
                              image: bgImage3,
                              width: 595.28,
                              height: 841.89,
                              pageBreak: 'after',

                            },
                            {
                              image: bgImage4,
                              width: 595.28,
                              height: 841.89,
                              pageBreak: 'after',

                            },
                            {
                              image: bgImage5,
                              width: 595.28,
                              height: 841.89,
                              pageBreak: 'after',

                            },
                            {
                              image: bgImage6,
                              width: 595.28,
                              height: 841.89,
                              pageBreak: 'after',

                            },
                            {
                              image: bgImage7,
                              width: 595.28,
                              height: 841.89,
                              pageBreak: 'after',

                            },
                            {
                              image: bgImage8,
                              width: 595.28,
                              height: 841.89,
                            },
                            {
                              image: bgImage9,
                              width: 595.28,
                              height: 841.89,
                            },
                            {
                              image: bgImage10,
                              width: 595.28,
                              height: 841.89,
                            },
                          ]
                        }
                        const pdfDocGenerator = pdfmake.createPdf(docDefinition);
                        pdfDocGenerator.getBlob((blob) => {
                          var file = new Blob([blob], { type: 'application/pdf' });
                          this.draftdealerData = file;
                          var fileURL = URL.createObjectURL(file);
                          this.fileUrl = fileURL;
                          if (this.dealershipDraft || this.dealershipPending) {
                            setTimeout(function () {
                              (<HTMLInputElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                              this.pdfLoading = false;
                            }.bind(this), 2500);
                          }
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });

    // playground requires you to assign document definition to a variable called dd


  }

  makeBase64(fileName): Promise<string> {

    return new Promise(resolve => {
      //console.log(new Date());
      var xhr = new XMLHttpRequest();
      // xhr.open('GET', 'assets/enrollment_form/Dealer_Enrollment_Form_' + fileName + '.jpg', true);
      xhr.open('GET', 'assets/enrollment_form/DE_' + fileName + '.jpg', true);
      xhr.responseType = 'arraybuffer';
      xhr.onload = function (e: any) {
        var binary = '';
        var arr = new Uint8Array(e.currentTarget.response);
        var len = arr.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(arr[i]);
        }
        var b64 = btoa(binary);
        var dataURL = "data:image/jpeg;base64," + b64;
        resolve(dataURL);
      };
      xhr.onerror = function () {
        resolve('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==');
      }
      xhr.send();
    });
  }

  expandForm() {
    if (this.status == 'DRAFT' || this.status == 'PENDING UPLOAD' || this.status == 'PENDING WITH PRINCIPAL' || this.status == 'PENDING WITH GM') {
      if (!this.checkChrome) {
        setTimeout(function () { this.pdfLoading = true; }.bind(this), 500);
        window.navigator.msSaveOrOpenBlob(this.draftdealerData, 'Enrollment_Form.pdf')
      } else {
        this.pdfLoading = false;
        $('#myModal2').modal('show');
      }
    }
  }
  closeForm() {
    this.pdfLoading = true;
  }
}
